import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FollowUpService } from '@core/follow-up/services/follow-up.service';
import { FirestoreService } from '@shared/services/firestore.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-job-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  @Input() job;
  @Output() closed = new EventEmitter<Boolean>();
  notes$ : Observable<any>
  form : FormGroup;

  constructor(
    private fb: FormBuilder,
    public followUpService : FollowUpService,
    private database: FirestoreService
  ) { 
    this.form = this.fb.group({
      note: this.fb.control(null, Validators.required),
      createdAt: this.fb.control(new Date().toISOString()),
    })
  }

  ngOnInit(): void {
    this.notes$ = this.followUpService.getFollowUpNotes(this.job.meta.id)
  }

  done() {
    this.closed.emit(false)
  }

  clearForm() {
    this.form.reset()
  }

  onSubmit() {
    if (this.form.valid) {
      this.save()
    }
  }

  async save() {
    const jobId = this.job.meta.id
    if (!jobId) return
    const reminder = this.form.value
    try {
      await this.database.col(`follow-ups/${jobId}/notes`).add(reminder);
      this.clearForm()
    } catch (err) {
      console.log(err)
    }
  }
}
