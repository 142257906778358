import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from './directives/directives.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes/pipes.module'
import { IconComponent } from './components/icon/icon.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { LoaderComponent } from './components/loader/loader.component';
import { ProposalPreviewComponent } from './components/proposal-preview/proposal-preview.component';
import { TemplatePreviewComponent } from './components/template-preview/template-preview.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProposalCondensedExpandableComponent } from './components/proposal-condensed-expandable/proposal-condensed-expandable.component';
import { LoaderOverlayComponent } from './components/loader-overlay/loader-overlay.component';
import { LoaderListComponent } from './components/loader-list/loader-list.component';
import { ThanksComponent } from './components/thanks/thanks.component';
import { TonnageCalculatorComponent } from './components/tonnage-calculator/tonnage-calculator.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SelectedSystemComponent } from './components/selected-system/selected-system.component';
import { CheckboxGridCellComponent } from './components/customer-comparison-grid/checkbox-grid-cell/checkbox-grid-cell.component';
import { FeatureDescriptionModalComponent } from './components/customer-comparison-grid/feature-description-modal/feature-description-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { OptionSavingsComponent } from './components/option-savings/option-savings.component';
import { FormattedOptionCardComponent } from './components/formatted-option-card/formatted-option-card.component';
import { PsisComparisonGridOptionComponent } from './components/psis/comparison-grid/psis-comparison-grid-option/psis-comparison-grid-option.component';
import { ListItemComponent } from '@core/dartboard/all/list/list-item/list-item.component';
import { JobStatusTotalsComponent } from './components/kpis/charts/job-status-totals/job-status-totals.component';
import { ChartsModule } from 'ng2-charts';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { MessageComponent } from './components/message/message.component';
import { LinkedJobSingleSoldBreakdownComponent } from './components/linked-job-single-sold-breakdown/linked-job-single-sold-breakdown.component';
import { NotesComponent } from '@core/dartboard/notes/notes.component';

const SharedModules = [
  CommonModule,
  RouterModule,
  PipesModule,
  FormsModule,
  ReactiveFormsModule,
  DirectivesModule,
  PortalModule,
  MatExpansionModule,
  MatDialogModule,
  MatMenuModule,
  ChartsModule,
];

// Only list components that are used frequently throughout the entire app.
// (Global components that are used throughout the app, but are not frequently used, import directly into component/module where it is used.)
const SharedComponents = [
  IconComponent,
  LoaderComponent,
  LoaderOverlayComponent,
  LoaderListComponent,
  ProposalPreviewComponent,
  TemplatePreviewComponent,
  ProposalCondensedExpandableComponent,
  SelectedSystemComponent,
  TonnageCalculatorComponent,
  ResetPasswordComponent,
  FormattedOptionCardComponent,
  CheckboxGridCellComponent,
  FeatureDescriptionModalComponent,
  PsisComparisonGridOptionComponent,
  ListItemComponent,
  JobStatusTotalsComponent,
  ConfirmModalComponent,
  MessageComponent,
  LinkedJobSingleSoldBreakdownComponent,
  NotesComponent

];

@NgModule({
  declarations: [
    SharedComponents,
    ThanksComponent,
    OptionSavingsComponent
  ],
  imports: [
    SharedModules,
    OverlayModule
  ],
  exports: [
    SharedModules,
    SharedComponents
  ],
  providers: []
})
export class SharedModule { }
