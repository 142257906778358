import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public tabsData = [
    {
      name: 'Users',
      routerLink: ['users']
    },
    {
      name: 'Templates',
      routerLink: ['templates']
    },
    {
      name: 'Company',
      routerLink: ['branding']
    },
    {
      name: 'Accounting',
      routerLink: ['pricing']
    },
    {
      name: 'Follow-ups',
      routerLink: ['follow-ups']
    },
    {
      name: 'Cutsheet',
      routerLink: ['cutsheet']
    },
    {
      name: 'Sys. Requirements',
      routerLink: ['addOns']
    },
    {
      name: 'Products',
      routerLink: ['products']
    },
    {
      name: 'Features',
      routerLink: ['features']
    },
    {
      name: 'Promotions',
      routerLink: ['promotions']
    },
    {
      name: 'Survey',
      routerLink: ['surveys']
    },
    {
      name: 'Files',
      routerLink: ['files']
    },
    {
      name: 'Archives',
      routerLink: ['archives']
    }
]

  constructor() { }

  ngOnInit() {
  }

}
