import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { JobsService } from '@shared/services/jobs.service'
import { QueueToggleService } from "@shared/services/queue-toggle.service";
import { FirestoreService } from "@shared/services/firestore.service";
import { AuthService } from "@shared/services/auth.service";
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.scss']
})
export class ArchivesComponent implements OnInit {

  selectedJob;

  loading: boolean = false;
  success: boolean = false;
  error: boolean = false;
  searchTerm$ = new BehaviorSubject<string>('');
  filteredJobs$ = combineLatest([
    this.jobsService.jobsDeleted$,
    this.searchTerm$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    )
  ]).pipe(
    map(([jobs, term]) => this.filterJobs(jobs, term))
  );

  constructor(
    public authService : AuthService,
    private database: FirestoreService,
    public queueToggleService: QueueToggleService,
    public jobsService : JobsService,
    private storage : AngularFireStorage
  ) { }

  ngOnInit(): void {
  }

  private filterJobs(jobs: any[], term: string): any[] {
    if (!term) return jobs;
    const searchTerm = term.toLowerCase();
    
    return jobs.filter(job => {
      return [
        job.info.project?.toLowerCase(),
        job.info.lastName?.toLowerCase(),
        job.info.firstName?.toLowerCase(),
        job.meta?.id?.toLowerCase()
      ].some(value => value?.includes(searchTerm));
    });
  }

  setActiveJob(job) {
    this.selectedJob = job
  }

  async permanentlyDeleteJob(job) {
    const jobID = job.meta.id;
    if (!jobID) console.log('Job Not Found... cant delete')

    if (job?.cutsheet?.images?.length) {
      this.deleteAssociatedImages(job)
    }

    try {
      this.database.doc(`archivedJobs/${job.meta.id}`).delete();
      this.selectedJob = null;
    } catch (err) {
      this.error = true
    }
  }

  async deleteAssociatedImages(job) {
    try {
      await this.storage.ref(`jobs/${job.meta.id}`).delete()
    } catch (err) {
      console.log(err)
    }
  }

  async restoreJob() {
    const job = this.selectedJob;
    this.loading = true;
    try {
      // Add job to other archivedJobs array
      await this.database.doc(`jobs/${job.meta.id}`).set(job);
      // Delete the job from the "active" jobs
      await this.database.doc(`archivedJobs/${job.meta.id}`).delete();
      this.success = true;
      this.loading = false;
      this.selectedJob = null;
    } catch (err) {
      this.error = true
    }
    this.loading = false;

  }

}
