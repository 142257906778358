import { AfterViewInit } from '@angular/core';
import { ProposalChoicesBreakdownComponent } from './proposal-choices-breakdown/proposal-choices-breakdown.component';
import { TemplatesService, Template } from "@shared/services/templates.service";
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import { BrandingService } from "@shared/services/branding.service";
import { JobsService } from "@shared/services/jobs.service";
import { FeatureService } from "@shared/services/features.service";
import { ProposalsService, SelectedSystem, Proposal } from "@shared/services/proposals.service";
import { AdminPricingService, FinancingOption } from "@shared/services/admin-pricing.service";
import { AddOn } from "@shared/services/add-on.service";
import { empty, Observable, Subscription} from "rxjs";
import { Router } from "@angular/router";
import { FirestoreService } from "@shared/services/firestore.service";
import{ DateCalculationsService } from "@shared/services/date-calculations.service";
import { DatePipe,DecimalPipe } from '@angular/common';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureDescriptionModalComponent } from '@shared/components/customer-comparison-grid/feature-description-modal/feature-description-modal.component';
import { ProposalPricingService } from "@shared/services/proposal-pricing-service";
import { ProposalChoicesService } from './proposal-choices.service';
import { tap, shareReplay, take } from 'rxjs/operators';
import { ProposalNotificationsService } from '@shared/services/proposal-notifications.service';

@Component({
  selector: 'app-proposal-choices',
  templateUrl: './proposal-choices.component.html',
  styleUrls: ['./proposal-choices.component.scss']
})
export class ProposalChoicesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(ProposalChoicesBreakdownComponent) breakdown;

  @Input() tonnage : string;
  @Input() proposal : Template;
  @Input() job;
  @Input() selectedSystem;
  @Input() isCustomer;

  pricingSub : Subscription;
  awaitingData: boolean = true;
  isSaving: boolean = false;
  isAdHoc: boolean = false;
  loading: boolean = true;
  calculating: boolean = true;
  isConsultantBoxOpen: boolean = true;
  isAddingDeposit: boolean = false;
  isComparisonGridOpen: boolean = false;
  comparisonGridFilterBrand = '';
  comparisonGridFilterSeerFrom = 14;
  comparisonGridFilterSeerTo = 33;

  accountingSub : Subscription;
  featureSub : Subscription;
  featureListRefined : any[] = [];

  isSplitPayment: boolean = false;

  currentlySelectedSystem;
  currentlySelectedEnhancements = [];

  paymentOption: string = "financing";

  cfa: number; // credit fee adjustment (+,-,0)
  dp : number; // deposit


  f: number; // credit fee
  r: number; // tax rate
// this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , preTaxTotal : preTaxTotal })
  financedTotal: number = 0;

  tonnageMatchedFormattedOptions : SelectedSystem[] = [];

  financingOptions : any[] = [];
  financingOptionIndex = 0;
  selectedFinancingOption : FinancingOption;
  selectedFinancingDetails : any;
  financePayment : number = 0;
  financePaymentTotal : number = 0;
  financedAmount : number = 0;
  financedAmountWithFee : number = 0;
  // financedAmountFee : number = 0;
  financingApplicationURL : string;


  templateGridSizeMax : number = 1;

  isCostBreakdownOpen: boolean = false;
  isFinancingBreakdownOpen: boolean = true;

  isConfirmSignOpen: boolean = false;
  isSold: boolean;
  isConfirmedRead: boolean = false;
  isConfirmedPay: boolean = false;
  customerSignature: string;

  customerMessage: string;
  isCustomerMessageSaved: boolean = false;

  isConsultantConfirmSaveOpen: boolean = false;

  deposit = {
    due: null,
    amount: null,
    cfa: 0,
    adjustedAmount: null,
    balance: null,
    previousBalance: null,
    type: 'cash',
    time: Date.now(),
    notes: null
  }

  selectedSystemLinks = []

  @Output() optionChose = new EventEmitter<object>();
  @Output() enhancementsChose = new EventEmitter<any[]>();
  @Output() optionTotal = new EventEmitter<number>();
  template$: Observable<any>

  constructor(
    private database: FirestoreService,
    public jobsService: JobsService,
    public brandingService: BrandingService,
    public proposalsService : ProposalsService,
    private proposalPricingService : ProposalPricingService,
    public adminPricingService : AdminPricingService,
    public featureService : FeatureService,
    private router: Router,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private dateCalculationsService : DateCalculationsService,
    public dialog: MatDialog,
    public currentProposalChoicesService : ProposalChoicesService,
    public proposalNotificationsService : ProposalNotificationsService
  ) {
  }

  ngOnInit(): void {
    this.pricingSub = this.adminPricingService.accounting$.subscribe( data => {
      this.paymentOption = data?.proposalDefaultPaymentMethod || "financing";
      this.f = this.job?.psis?.meta?.accounting?.creditFee || data.creditPriceAdminCosts;
      this.dp = data.depositPercentage;
      this.financingOptions = data.financingOptions;
      this.financingApplicationURL = data.financingApplicationURL;
      this.setUp();
      this.customerMessage = this.job?.customerMessage ? this.job.customerMessage : null
    });

    this.featureSub = this.featureService.features$.subscribe( adminFeatureList => {
      if(this.job.template == '0000') {
        this.isAdHoc = true;
      } else {
        this.refineAdminFeatureList(adminFeatureList)
      }
    });


    if(this.isCustomer) {
      if (this.job?.meta?.jobsGroupId) {
        this.routeToLinkedView()
      }
    } else {
      this.toggleCollapseConsultantBox()
    }
    
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , cd : this.job.customDiscount.amount || 0 })
    if(!this.loading) {
      this.setUp()
    }
  }

  routeToLinkedView() {
    if (!this.job?.meta?.jobsGroupId) {
      return
    }
    this.router.navigate([`/proposals/linked/${this.job.meta.id}`])
  }

  async setUp() {
    const job = this.job;
    await job;
    await this.setInitialData();
    this.loading = false
    this.awaitingData = false;
  }

  async refineAdminFeatureList(adminFeatureList) {
    adminFeatureList.forEach( feature => {
        // this.checkTemplateForGridSpecificFeature(feature)

      if (this.featureService.staticOptionFeatures.includes(feature.id)) {
        if(!this.featureListRefined.some(item => item.id == feature.id)) {
          this.featureListRefined.push(feature);
        }
        // @TODO - only push when filled? hide in template? these are option / tonnage specific so are harder to see the value
        // this.checkTemplateForStaticOptionFeatureValue(feature)
      } else {
        this.checkTemplateForGridSpecificFeature(feature)
      }
    });
  }

  async checkTemplateForGridSpecificFeature(feature) {
    this.proposal?.brands.forEach( (brand,b) => {
      brand.options.forEach( (option : any, o) => {
        // we cant just check for truthy-ness, because 0 and 1 mean something different.
          // ignore if its null, undefined, ''
        if (
          (option.comparisonFeatures[feature.id] ||
          option.comparisonFeatures[feature.id] == 0 ||
          option.comparisonFeatures[feature.id] == 1) && option.comparisonFeatures[feature.id] !== ''
          ) {
            if(!this.featureListRefined.some(item => item.id == feature.id)) {
              this.featureListRefined.push(feature);
              return
            }
        }
      });
    })
  }

  async setInitialData() {

    this.adminPricingService.accounting$.pipe(take(1)).subscribe( accounting => {
      // Set any input specific values
      if (this.job.status == 'sold') {
        this.isSold = true;
        this.paymentOption = this.job.confirmation?.paymentMethod ? this.job.confirmation.paymentMethod : null
        this.currentlySelectedSystem = this.job?.system || this.selectedSystem;
      } else {
        this.isSold = false
        // select a system
        if (this.selectedSystem) {
          // If theres already a selected system, we set it
          this.selectSystem(this.selectedSystem)
          // this.currentlySelectedSystem = this.selectedSystem;
        } else if (accounting?.proposalHidePriceOnOption) {
          // If they have prices hiding turned on, we dont select an option
          this.currentlySelectedSystem = null;
        } else {
          // Select the first system 
          // this.currentlySelectedSystem = this.job?.psis?.brands[0].formattedOptions[0];
          this.selectSystem(this.job?.psis?.brands[0].formattedOptions[0])

        }
      }

      ////////////////////////////////////////////////////////////////////////////
      // Apply any previously selected enhancements input
      this.currentlySelectedEnhancements  = this.job.enhancements.filter(enhancement => this.isEnhancementApplicableToSelectedSystem(enhancement))
      
    })
    
    ////////////////////////////////////////////////////////////////////////////


  }

  resetConfirmModal() {
    this.isConfirmedPay = false;
    this.isConfirmedRead = false;
    this.customerSignature = null
  }

  toggleConfirmSignOpen() {
    this.isConfirmSignOpen = !this.isConfirmSignOpen
  }

  toggleIsComparisonGridOpen() {
    this.isComparisonGridOpen = !this.isComparisonGridOpen
  }

  closeComparisonGridAndReviewProposal() {
    this.isComparisonGridOpen = false;
    this.scrollToProposalOverview()
  }

  resetComparisonGridFilterSeer() {
    this.comparisonGridFilterSeerFrom = 14;
    this.comparisonGridFilterSeerTo = 33;
  }

  openDialog(feature) {
    this.dialog.open( FeatureDescriptionModalComponent, {
      data: {
        description: feature.description,
        name: feature.name
      }
    });
  }

  scrollToProposalOverview() {
    document.getElementById("proposalOverview").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  async sign() {

    if (!this.isConfirmedRead || !this.isConfirmedPay || !this.customerSignature || this.isSaving) return

    this.isSaving = true;

    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).update({
        'meta.jobsGroupId' : null, // if a job is signed solo, unlink it
        'status': 'sold',
        'system': this.currentlySelectedSystem,
        'enhancements': this.currentlySelectedEnhancements || null,
        'confirmation': {
          'signedAt': Date.now(),
          'paymentMethod': this.paymentOption,
          'customerSignature': this.customerSignature,
          'appliedPromotions': [...this.currentProposalChoicesService.currentlyAvailableDiscounts$.value,...this.currentProposalChoicesService.currentlyAvailableRebates$.value],
          'prices': this.currentProposalChoicesService.prices$.value,
          'enhancements' : [...this.currentlySelectedEnhancements],
          'addOns' : [...this.job.addOns],
        }
      });
      if (this.paymentOption == 'financing') {
        await this.database.doc(`jobs/${this.job.meta.id}`).update({
          'confirmation.selectedFinancingDetails': this.selectedFinancingDetails
        });
      }
      this.proposalNotificationsService.notifyOfSign(this.job)
      this.isSaving = false;
      await this.done();
      this.router.navigateByUrl(`thanks?proposal=${this.job.meta.id}`)
    } catch (err) {
      console.log(err)
      this.isSaving = false;
    }
    this.router.navigateByUrl(`thanks?proposal=${this.job.meta.id}`)
  }

  toggleCollapseConsultantBox() {
    this.isConsultantBoxOpen = !this.isConsultantBoxOpen
  }

  toggleConsultantVerify(state?:boolean) {
    if (state) {
      this.isConsultantConfirmSaveOpen = state
    } else {
      this.isConsultantConfirmSaveOpen = !this.isConsultantConfirmSaveOpen
    }
  }

  async done() {
    this.optionChose.emit(this.currentlySelectedSystem)
    this.optionTotal.emit(this.currentProposalChoicesService.prices$.value.total);

    if(this.isEnhancementsUnchanged(this.job.enhancements, this.currentlySelectedEnhancements)) {
    } else {
      this.enhancementsChose.emit(this.currentlySelectedEnhancements)
    }

    return;
  }

  close() {
    this.optionChose.emit()
  }

  async customerDone() {
    await this.done();
    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).update({
        'customerSelectedSystem': 'true'
      });
      this.router.navigateByUrl('thanks')
    } catch (err) {
      console.log(err)
    }
  }

  setPrices() {
    if(this.paymentOption == 'financing') {
      if (!this.job?.confirmation?.selectedFinancingDetails) {
        //  this.setFinancingOption(this.financingOptions[this.financingOptionIndex])
          setTimeout(()=>{
          // applies financing option, since that is stored as the index
          // If you go from credit to financing, the financing math uses the credit price before it has time to calc the cash payment (which should be the base)
          this.setFinancingOption(this.financingOptions[this.financingOptionIndex])
          }, 200);
      }
    }
  }

  setSelectedSystemLinks() {
    const selectedSystemId = this.currentlySelectedSystem.option;
    let links = null;

    this.proposal?.brands.find(brand => {
        const foundOption = brand.options.find(option => option.id === selectedSystemId);
        if (foundOption) {
            links = foundOption?.links || [];
            return true;
        }
        return false;
    });
    this.selectedSystemLinks = links
    
  }

  selectSystem(system) {
    this.currentlySelectedSystem = system;
    this.setSelectedSystemLinks()
    this.setPrices()
  }

  getSelectedSystem($event) {
    const newSystem = $event;
    if (newSystem == this.currentlySelectedSystem) return
    this.selectSystem($event)
  }

  isEnhancementsUnchanged(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  toggleEnhancement(e) {
    if (this.currentlySelectedEnhancements.some(enhancement => enhancement.name === e.name)) {
      this.currentlySelectedEnhancements = this.currentlySelectedEnhancements.filter(enhancement => enhancement.name !== e.name)
      this.setPrices()
    } else {
      // this.currentlySelectedEnhancements.push(e)
      this.currentlySelectedEnhancements = [...this.currentlySelectedEnhancements, e]
      this.setPrices()
    }
  }

 
  isEnhancementApplicableToSelectedSystem(e) {
    if (e.worksWithOptions.some(option => option == this.currentlySelectedSystem?.option)) {
      // If the worksWithOptions array contains the current system
      return true
    } else {
      return false
    }
  }


  getFee(amount) {
    return (this.f / 100) * amount;
  }

  // Deposit
  async calculateDeposit(depositType) {
    this.deposit.type = this.job.confirmation?.paymentMethod == 'financing' ? 'cash' : this.job.confirmation?.paymentMethod;
    const previousBalance = this.job?.balance ? this.job.balance : this.job.price;
    const due = parseFloat((this.dp * ( previousBalance / 100 )).toFixed(2))
    this.deposit.due = due;
    this.deposit.amount = due;
    this.deposit.previousBalance = previousBalance;
    this.updateDeposit(this.deposit.type)
  }

  async updateDeposit(depositType) {

    let cfa = 0
    let amountApplied = 0;

    if (this.job.psis.meta.accounting.feeModel == 1 || depositType == this.job.confirmation?.paymentMethod) {
       // Fee model == 1 or deposit matches job payment, then there is no fee adjustment, the fee is built into the base prices
      amountApplied = this.deposit.amount

    } else {


      if (this.job.confirmation?.paymentMethod == 'cash' && depositType == 'credit') {

        // If its a credit deposit on cash job, we need to subtract the true fee from the deposit
        cfa = this.getFee(this.deposit.amount);
        amountApplied = this.deposit.amount - cfa;

      } else if (this.job.confirmation?.paymentMethod == 'credit' && depositType == 'cash') {
        // If its a cash deposit on a credit job, we need to apply a cash rebate
        const f = this.job.psis.meta.accounting.creditFee / 100;
        const t = this.job.psis.meta.accounting.salesTax / 100;
        cfa = this.proposalPricingService.addCompoundingPercentageWithTax(this.deposit.amount,f,t) - this.deposit.amount
        amountApplied = this.deposit.amount + cfa;

      } else {
        amountApplied = this.deposit.amount
      }

    }

    this.deposit.adjustedAmount = amountApplied;
    this.deposit.balance = this.deposit.previousBalance - amountApplied;
    this.deposit.cfa = cfa;
  }

  async applyDeposit() {
    this.loading = true;
    let deposits = this.job?.deposits ? this.job.deposits : [];
    this.deposit.previousBalance = this.job?.balance ? this.job.balance : this.job.price;
    this.deposit.time = Date.now();
    deposits.push(this.deposit)
    let depositsTotal = this.job?.depositsTotal ? this.job?.depositsTotal : 0;

    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).update({
        'deposits': deposits, // add deposit to deposit array
        'balance': this.deposit.balance, // update price to this new balance
        'depositsTotal' : depositsTotal += this.deposit.adjustedAmount
      });
      this.toggleAddDeposit(false);

      if (this.job?.confirmation?.paymentMethod == 'financing') {
        this.updateFinancingAfterDeposit(this.deposit.balance);
      }
      this.loading = false;

    } catch (err) {
      console.log(err)
      this.loading = false;
    }

  }

  async updateFinancingAfterDeposit(balanceAfterDeposit) {

    if (this.job?.confirmation?.selectedFinancingDetails?.selectedFinancingOption) {

      let pmt;
      let fin = this.job?.confirmation?.selectedFinancingDetails?.selectedFinancingOption;
      const p = balanceAfterDeposit;
      const b = balanceAfterDeposit;
      // let p = balanceAfterDeposit / (1 - (fin.fee / 100));

      let finr = fin.apr / 1200; // divide by 100 to convert to decimal and by 12 to get monthly rate

      if (fin.apr == 0 && !fin.min) {
        pmt = p / fin.months;
      } else if (!fin.min) {
        pmt = p * finr * Math.pow(1 + finr,fin.months) / (Math.pow(1 + finr, fin.months) - 1);
      } else if (fin.min) {
        pmt = p * fin.min / 100;
      }

      this.financePayment = pmt;


      const pmtFormatted = parseFloat(this.decimalPipe.transform(pmt, '0.2-2'))
      this.financePaymentTotal = pmtFormatted * fin.months;
      this.financedAmount = p;


      let updatedSelectedFinancingDetails = {
        'financingBasisPrice' : b,
        'financePayment' : pmt,
        'financePaymentsTotalAtTermEnd': this.financePaymentTotal,
        'financedAmount': this.financedAmount,
      }

      try {
        await this.database.doc(`jobs/${this.job.meta.id}`).set({
          'confirmation': {
            'selectedFinancingDetails' : updatedSelectedFinancingDetails
          }
        }, { merge: true });
      } catch (err) {
        console.log(err)
      }
    }



  }

  async saveCustomerMessage() {
    if (this.customerMessage != this.job?.customerMessage) {
      try {
        await this.database.doc(`jobs/${this.job.meta.id}`).update({
          'customerMessage': this.customerMessage,
        });
        this.showCustomerMessageSuccessMessage()
      } catch (err) {
        console.log(err)
      }
    }
  }

  showCustomerMessageSuccessMessage() {
    this.isCustomerMessageSaved = true;
    setTimeout(()=>{
      this.isCustomerMessageSaved = false;
    }, 3000);
  }

  toggleAddDeposit(state?:boolean) {
    this.calculateDeposit(this.job.confirmation?.paymentMethod)
    if(state) {
      this.isAddingDeposit = state
    } else {
      this.isAddingDeposit = !this.isAddingDeposit
    }
  }

  // Add a discount percentage
  getPercentageOfNumber(number: number, percentage: number) {
    return percentage * ( number / 100 );
  }

  // Calculate the monthly payment for a financing option
  async setFinancingOption(financingOption) {
    let pmt;
    const fin = financingOption;
    const total = this.currentProposalChoicesService.prices$.value.total;
    const b = this.currentProposalChoicesService.prices$.value.total; 

    // const p = b / (1 - (fin.fee / 100));
    const p = b; // p set to straight price, cant add fees anymore
    const finr = fin.apr / 1200; // divide by 100 to convert to decimal and by 12 to get monthly rate

    // 0% APR and equal payments
    if (fin.apr == 0 && !fin.min) {
      pmt = p / fin.months;

      // Not 0% APR but equal payments
    } else if (!fin.min) {
      pmt = p * finr * Math.pow(1 + finr,fin.months) / (Math.pow(1 + finr, fin.months) - 1);
      
      // Non-equal payments (ability to make smaller minimum payments)
    } else if (fin.min) {
      pmt = p * fin.min / 100;
    }
    const option = financingOption;
    await option;
    this.selectedFinancingOption = option;
    this.financePayment = pmt;

    const pmtFormatted = parseFloat(this.decimalPipe.transform(pmt, '0.2-2'))
    this.financePaymentTotal = pmtFormatted * fin.months;
    this.financedAmount = p;

    this.selectedFinancingDetails = {
      'financingBasisPrice' : b,
      'selectedFinancingOption' : option,
      'total': total,
      'financePayment' : pmt,
      'financePaymentsTotalAtTermEnd': this.financePaymentTotal,
      'financedAmount': this.financedAmount,
      'financedOriginalTotal': this.financedAmount, // differs because amount can change after deposits
    }
  }


  ngOnDestroy() {
    this.pricingSub.unsubscribe();
    this.featureSub?.unsubscribe()
  }

}
