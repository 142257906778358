import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-linked-job-pricing',
  templateUrl: './linked-job-pricing.component.html',
  styleUrls: ['./linked-job-pricing.component.scss']
})
export class LinkedJobPricingComponent implements OnInit {
  @Input() job;
  @Input() linkedPrices;
  currentJobConfirmationPrices;

  constructor() { }

  ngOnInit(): void {
    // this.jobPrices = this.linkedPrices.find(lp => lp.proposalId == this.job.meta.id)
      
    this.currentJobConfirmationPrices = this.job?.confirmation?.linkedPrices.find(job => job.proposalId === this.job.meta.id)
  }

}
