import { Injectable , OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { FirestoreService } from "@shared/services/firestore.service";
import { AuthService } from "@shared/services/auth.service";
import { debounceTime, map, shareReplay, switchMap } from "rxjs/operators";
import { CondensedJob, JobsService } from "@shared/services/jobs.service";

export interface ColumnState {
  isVisible: boolean,
  label: string
  state: string
}
@Injectable({
  providedIn: "root"
})
export class FollowUpService implements OnDestroy {
  private readonly storageKey = 'viewableColumns';
  columnsSub : Subscription;
  columns$ : Observable<any>;

  isRemindersVisible$ = new BehaviorSubject<boolean>(false)
  filteredJobs$ : Observable<CondensedJob[]>;
  isShowSoldJobsInColumns$ = new BehaviorSubject<boolean>(false);
  searchFilter$ = new BehaviorSubject<string>('');
  consultantFilter$ = new BehaviorSubject<string>('');
  dateFilter$ = new BehaviorSubject<{ type: string, start?: Date, end?: Date }>({ type: 'all' });

  thresholdForRecentDays = 14;

  public viewableColumns$ = new BehaviorSubject<{ [key: string]: { isVisible: boolean, label: string } }>(
    this.loadFromLocalStorage() || {  // Load from localStorage or use defaults
      lost: { isVisible: false, label: 'lost' },
      sold: { isVisible: false, label: 'sold' },
    }
  );

  constructor(
    public db: AngularFirestore,
    public database: FirestoreService,
    private authService : AuthService,
    private jobsService : JobsService
  ) {

    this.filteredJobs$ = combineLatest([
      this.jobsService.viewableJobsCondensed$,
      this.searchFilter$.asObservable().pipe(debounceTime(500)),
      this.dateFilter$.asObservable(),
    ]).pipe(
      map( ([jobs, searchTerm, dateFilter]) => {
        return jobs
        .filter(job => this.matchJobSearch(job, searchTerm))
        .filter(job => !job?.meta?.isAdditionalJob)
        .filter(job => this.matchDateFilter(job, dateFilter))

      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    this.columnsSub = this.viewableColumns$.subscribe(columns => this.saveToLocalStorage(columns));  

    this.columns$ = this.database.doc$('admin/follow-ups').pipe(
      map(
        (data: any) => {
          return data?.columns || []
        }
      ),
      shareReplay({bufferSize: 1, refCount: true})
    )

  }

  ngOnDestroy() {
    this.columnsSub?.unsubscribe()
  }

  getJobDate(job: any): Date {
    const dateString = job.meta.created;
    return new Date(dateString);
  }

  toLocalMidnight(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  matchDateFilter(job: any, dateFilter: any): boolean {

    if (!dateFilter || dateFilter.type === 'all') return true;

    const jobDate = this.toLocalMidnight(this.getJobDate(job));

    switch (dateFilter.type) {
      case 'recent':
        const cutoffRecent = new Date();
        cutoffRecent.setDate(cutoffRecent.getDate() - this.thresholdForRecentDays);
        return jobDate >= this.toLocalMidnight(cutoffRecent);

      case 'month':
        const cutoffMonth = new Date();
        cutoffMonth.setDate(cutoffMonth.getDate() - 30);
        return jobDate >= this.toLocalMidnight(cutoffMonth);

      case 'year':
        return jobDate.getFullYear() === new Date().getFullYear();

      case 'range':
        if (!dateFilter.start || !dateFilter.end) return true;
        const start = this.toLocalMidnight(dateFilter.start);
        const end = new Date(dateFilter.end);
        end.setHours(23, 59, 59, 999);
        return jobDate >= start && jobDate <= end;

      default:
        return true;
    }
  }

  getActiveColumnData$(state:string) {
    return combineLatest([
      this.authService.user$.pipe(
      switchMap((user: any) => {
        if (user.tier == 2) {
          return this.database.col$('jobs', ref => ref
            .where('meta.createdBy', '==', user.uid)
            .where('state','==',state)
            .orderBy('stateLastUpdated', 'desc')
          ).pipe(
            map((documents: any[]) => {
              return this.jobsService.condenseJobsData(documents)
            }),
            shareReplay({bufferSize: 1, refCount: true})
          )
        } else {
          return this.database.col$('jobs', ref => ref
            .where('state','==',state)
            .orderBy('stateLastUpdated', 'desc')
          ).pipe(
            map((documents: any[]) => {
              return this.jobsService.condenseJobsData(documents)
            }),
            shareReplay({bufferSize: 1, refCount: true})
          )
        }
      }),
      shareReplay({bufferSize: 1, refCount: true})
    ),
      this.searchFilter$.asObservable().pipe(debounceTime(500)) 
    ]).pipe(
      map( ([jobs, searchTerm]) => {
        return jobs.filter(job => this.matchJobSearch(job, searchTerm)).filter(job => !job?.meta?.isAdditionalJob).filter(job => job.status == 'pending')
        ;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    )


  }

  toggleViewableColumnVisibility(column: string) {
    const currentColumns = this.viewableColumns$.value;
    const newColumns = {
      ...currentColumns,
      [column]: {
        ...currentColumns[column],
        isVisible: !currentColumns[column].isVisible,
      },
    };
    this.viewableColumns$.next(newColumns);
  }

  toggleIsRemindersVisible() {
    this.isRemindersVisible$.next(!this.isRemindersVisible$.value)
  }

  getFollowUpHistory(id:string) {
    return this.database.col$(`follow-ups/${id}/history`, ref => ref
      .orderBy('timestamp', 'asc')).pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      )
  }

  getFollowUpNotes(id:string) {
    return this.database.col$(`follow-ups/${id}/notes`, ref => ref
      .orderBy('createdAt', 'desc')).pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      )
  }

  async updateJobState(jobId, status, previousState, stateLastUpdated) {
    if (!jobId) return
    try {
      await this.database.doc(`jobs/${jobId}`).update({
        'status': status == 'lost' ? 'lost' :  'pending',
        'state': status,
        'stateLastUpdated': new Date().toISOString(),
      });
      const historyEntry = {
        jobId: jobId,
        previousState: previousState,
        newState: status,
        timestamp: new Date().toISOString(),
        stateLastUpdated: stateLastUpdated,
      };
      await this.database.col(`follow-ups/${jobId}/history`).add(historyEntry);

    } catch (err) {
      console.log(err)
    }
  }

  matchJobSearch(job, searchTerm: string): boolean {
    if (!searchTerm) return true;
    const lowerSearch = searchTerm.toLowerCase();
    return (
      (job.info?.firstName && job.info?.firstName.toLowerCase().includes(lowerSearch)) ||
      (job.info?.lastName && job.info?.lastName.toLowerCase().includes(lowerSearch))
    );
  }

  saveToLocalStorage(columns: { [key: string]: { isVisible: boolean, label: string } }) {
    localStorage.setItem(this.storageKey, JSON.stringify(columns));
  }

  loadFromLocalStorage(): { [key: string]: { isVisible: boolean, label: string } } | null {
    const savedColumns = localStorage.getItem(this.storageKey);
    return savedColumns ? JSON.parse(savedColumns) : null;
  }

}
