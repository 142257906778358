import { CalendarService } from '@shared/services/calendar.service';
import { UserService } from '@shared/services/user.service';
import { LeadsService } from '@shared/services/leads.service';
import { Component, OnInit, OnDestroy, Input, SimpleChanges  } from '@angular/core';
import { QueueToggleService } from "@shared/services/queue-toggle.service";
import { JobsService } from "@shared/services/jobs.service";
import { AuthService } from "@shared/services/auth.service";
import { DartboardFilterService } from "@shared/services/dartboard-filter.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadModalComponent } from '@shared/components/lead-modal/lead-modal.component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
@Component({
  selector: 'app-jobs-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy  {

  // @Input() jobsList : any[];

  routeSub: Subscription;
  filterSub: Subscription;
  isFiltersOpen : boolean = false;
  activeFilter: string = 'pending';
  activeJobId : string;

  private jobsList$ = new BehaviorSubject<any[]>([]);
  searchTerm$ = new BehaviorSubject<string>('');

    // Input array of jobs
  @Input() set jobsList(value: any[]) {
    this.jobsList$.next(value ?? []);
  }

  constructor(
    public queueToggleService: QueueToggleService,
    private route: ActivatedRoute,
    public jobsService: JobsService,
    public authService: AuthService,
    public dartboardFilterService : DartboardFilterService,
    public dialog: MatDialog,
    public leadsService : LeadsService,
    public userService : UserService,
    public calendarService : CalendarService
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.activeJobId = params.id;
      if(this.route.snapshot.queryParamMap.get('filter')||0) {
        let filterParam = this.route.snapshot.queryParamMap.get('filter').toLowerCase();
        if ( filterParam != 'pending') {
          this.isFiltersOpen = true;
        }
        this.activeFilter = filterParam;
        this.dartboardFilterService.setDartboardFilter(filterParam)
      }
    });
    this.filterSub = this.dartboardFilterService.dartboardFilter$.subscribe( state => {
      if (this.activeFilter != state) {
        this.activeFilter = state;
        this.isFiltersOpen = true;
      }
    })

  }

ngOnInit() {
 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['jobsList']) {
      if(changes['jobsList']?.currentValue?.length != changes['jobsList']?.previousValue?.length) {
        if (this.activeJobId) {
          setTimeout(()=>{
            this.scrollSelectedJobIntoView(this.activeJobId)
          }, 1000);
        }

      }

    }
    
  }

  // Filtered jobs observable
  filteredJobs$ = combineLatest([
    this.jobsList$,
    this.searchTerm$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    )
  ]).pipe(
    map(([jobs, term]) => this.filterJobs(jobs, term))
  );

  private filterJobs(jobs: any[], term: string): any[] {
    if (!term) return jobs;
    const searchTerm = term.toLowerCase();
    
    return jobs.filter(job => {
      return [
        job.info?.project?.toLowerCase(),
        job.info?.lastName?.toLowerCase(),
        job.info?.firstName?.toLowerCase(),
        job.meta?.id?.toString().toLowerCase()
      ].some(value => value?.includes(searchTerm));
    });
  }
  toggleIsFiltersOpen() {
    this.isFiltersOpen = !this.isFiltersOpen
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe()
    this.filterSub.unsubscribe()
  }

  openLead(lead) {
    this.dialog.open( LeadModalComponent, {
      data: {
        lead: lead
      }
    });
  }

  scrollSelectedJobIntoView(id) {
    let target = document.getElementById(id);
    if(target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

}
