import { CustomerProposalService } from '@shared/services/customer-proposal.service';
import { UserService } from '@shared/services/user.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { FirestoreService } from "@shared/services/firestore.service";
import { AuthService } from "@shared/services/auth.service";
import { AddOnService } from "@shared/services/add-on.service";
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { ActivatedRoute, Router } from "@angular/router";
import { QueueToggleService } from "@shared/services/queue-toggle.service";
import { Observable,Subscription } from 'rxjs';
import { ProposalsService } from "@shared/services/proposals.service";
import { TemplatesService } from "@shared/services/templates.service";
import { SurveyService } from "@shared/services/survey.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { DartboardFilterService } from "@shared/services/dartboard-filter.service";
import { finalize, map, shareReplay } from 'rxjs/operators';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CutsheetService } from '@shared/services/cutsheet.service';
import { JobsService } from '@shared/services/jobs.service';
import { LinkedJobsComponent } from './detail-view/linked-jobs/linked-jobs.component';
import { AdminPricingService } from '@shared/services/admin-pricing.service';
import { DateCalculationsService } from '@shared/services/date-calculations.service';

interface IResizeImageOptions {
  maxSize: number;
  file: File;
}

@Component({
  selector: "app-job-details",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-details.component.scss"]
})
export class JobDetailsComponent implements OnInit, OnDestroy {

  isNewTemplate: boolean = false;

  isMessageOpen: boolean = false;
  isConfirmDelete: boolean = false;
  isConfirmUnsign: boolean = false;

  routeSub: Subscription;
  userSub: Subscription;
  jobSub: Subscription;

  proposalTemplatesSub: Subscription;
  proposalTemplateSub: Subscription;
  oldProposalTemplateSub: Subscription;

  job$: Observable<any>;
  job;
  jobCache;
  currentJobId: string;
  totalPrice: number = 0;
  isLinkedProposal: boolean = false;
  proposal;
  template$: Observable<any>

  isCustomer: boolean = true;
  isPresenting: boolean = true;
  isDraft: boolean = false;
  isSold: boolean = false;
  hasPriceDiscrepancy: boolean = false;

  isEditing: boolean = false;
  isEditingCutsheet : boolean = false;
  isSelectTemplate: boolean = false;
  hasEdited: boolean = false;
  savingCutsheet: boolean = false;
  isEditDiscount: boolean = false;
  selectedDiscount = null;
  isAddCustomDiscountsItem: boolean = false;
  selectedCustomDiscountsItem = null;
  isAddOnsOpen: boolean = false;
  editableFieldsForm: NgForm;
  editableFieldsFormCutsheet: NgForm;
  editableFieldsFormProposal: NgForm;
  surveyQuestions$: Observable<any>

  availablePromos: any[] = [];
  optionalPromos: any[] = [];
  // isCutsheetShowEmptyFields: boolean = true;
  isCutsheetCollapsed: boolean = false;
  
  availableTonnages = [0.75,1,1.25,1.5,2,2.5,3,3.5,4,4.5,5,6,6.5,7,7.5,10];
  isCreateCustomSystem : boolean = false;
  isViewImages: boolean = false;
  @ViewChild('cutsheetImageUpload')
  cutsheetImageUpload: ElementRef;

  loading: boolean = true;
  success: boolean = false;
  error: boolean = false;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  filterParam: string;

  today;

  @ViewChild(LinkedJobsComponent) linkedJobsComponent;
  // linkedJobsComponent: ElementRef;

  constructor(
    private database: FirestoreService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    public queueToggleService : QueueToggleService,
    public proposalsService : ProposalsService,
    public templatesService : TemplatesService,
    public userService : UserService,
    private surveyService : SurveyService,
    private storage: AngularFireStorage,
    public authService : AuthService,
    public addOnService : AddOnService,
    private decimalPipe: DecimalPipe,
    public dartboardFilterService : DartboardFilterService,
    public cutsheetService : CutsheetService,
    public customerProposalService : CustomerProposalService,
    private jobsService : JobsService,
    public adminPricingService : AdminPricingService,
    private dateCalculationsService : DateCalculationsService,
    private datePipe: DatePipe
  ) {

    this.queueToggleService.toggleQueueState(false)

    this.today = this.datePipe.transform(new Date(),"yyyy-MM-dd");


    this.surveyQuestions$ = this.surveyService.surveyQuestions$;

    this.userSub = this.authService.user$.pipe(
         map(user => {
           if(!user) {
             this.setCustomerView(true)
           } else {
             this.isCustomer = false;
           }
         })
     ).subscribe();



     this.routeSub = this.route.params.subscribe(params => {

       // Set presenting base on query
       if(this.route.snapshot.queryParamMap.get('presenting')||0) {
         this.setCustomerView(true)
       } else {
         this.isCustomer = false;
         this.isPresenting = false;
       }

       // Set filter base on query
       if(this.route.snapshot.queryParamMap.get('filter')||0) {
         this.filterParam = this.route.snapshot.queryParamMap.get('filter').toLowerCase();
         this.dartboardFilterService.setDartboardFilter(this.route.snapshot.queryParamMap.get('filter').toLowerCase())
       }

       // Set job data from url
       if (params.id != this.currentJobId) {
         this.isEditing = false;
         this.hasEdited = false;
         this.isViewImages = false;
         this.loading = true;
         this.resetUpload();
         this.job$ = this.jobsService.watchJob(params.id);
        //  this.job$ = this.database.doc$(`jobs/${params.id}`); // TODO = local ref, not db
         this.jobSub = this.job$.subscribe(
           job => {
             if (!job?.template) {
               this.isDraft = true;
               this.job = job;
             } else {
               this.isDraft = false;
               this.queueToggleService.closeQueueIfMobile();
               this.job = job;
               this.isLinkedProposal = this.job?.meta?.jobsGroupId ? true : false;
               this.hasPriceDiscrepancy = this.hasDepositDiscrepancy();
               this.jobCache = job;
               this.isSold = this.job?.status == 'sold' ? true : false;
               if(job?.template == '0000') {
                  this.isNewTemplate = true;
                  this.loading = false;
                  this.proposal;
                } else if (job.template) {
                  this.proposalTemplateSub = this.templatesService.watchTemplate$(job?.template).subscribe(
                    template => {
                      if (template) {
                        this.proposal = template;
                        this.isNewTemplate = true;
                        this.loading = false
                        if (job.status != 'sold') this.getSetPromos()
                      } else {
                        console.log('The current template is no longer supported. If it is not sold, please choose a new template')
                        this.oldProposalTemplateSub = this.proposalsService.getProposal$(job.template).subscribe(
                            proposal => {
                              this.proposal = proposal;
                              this.isNewTemplate = false;
                              if (job.status != 'sold') this.getSetPromos()
                              this.loading = false;
                            }
                          ), shareReplay({bufferSize: 1, refCount: true})
                        }
                    }
                  ), shareReplay({bufferSize: 1, refCount: true})
                } else {
                  this.loading = false;
                  this.isSelectTemplate = true
                }
             }
             if (job?.status && job?.status != this.dartboardFilterService.dartboardFilter$.value) {
               this.dartboardFilterService.setDartboardFilter(job.status)
               this.updateFilterParams(job.status);
             }
             shareReplay({bufferSize: 1, refCount: true})
           }
         )
         this.currentJobId = params.id;
       } else {
         // Maybe set a default
         this.loading = false;
       }
     });
  }

  ngOnInit(): void {
  }

  onMenuClosed() {
    this.savePsis()
  }

  handleToggleOption($event,b,o) {
    const isChecked = $event.target.checked
    this.job.psis.brands[b].formattedOptions[o].isHiddenForCustomer = isChecked
  }

  scrollSelectedJobIntoView(id) {
    let target = document.getElementById(id);
    if(target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

  hasDepositDiscrepancy() {
    if ( this.job.status == 'sold' && (((this.job?.price - this.job?.depositsTotal != this.job?.balance) && this.job?.depositsTotal) || (this.job?.price != this.job?.balance) && !this.job?.deposits) ) {
      return true
    } else {
      return false
    }
  }

  toggleIsConfirmDelete(state:boolean) {
    if(state) {
      this.isConfirmDelete = state
    } else {
      this.isConfirmDelete = !this.isConfirmDelete
    }
  }

  toggleMessageOpen(state:boolean) {
    if(state) {
      this.isMessageOpen = state
    } else {
      this.isMessageOpen = !this.isMessageOpen
    }
  }

  setCustomerView(state:boolean) {
    this.isPresenting = state;
    this.isCustomer = state;
  }

  getDone($event) {
    this.isMessageOpen = false;
  }

  getUnsignDone($event) {
    this.isConfirmUnsign = false;
  }

  async updateConsultant() {
    try {
      await this.database.col("jobs").doc(this.job.id).set({
        meta: {
          createdBy : this.job.meta.createdBy
        }
      }, { merge : true });
      return true
    } catch (err) {
      console.log(err)
    }
  }

  async saveCustomerMessage() {
    try {
        await this.database.doc(`jobs/${this.job.meta.id}`).update({
          'customerMessage': this.job.customerMessage,
        });
      } catch (err) {
        console.log(err)
      }
  }

  async getChoice($event) {
    if(!$event) {
      this.isPresenting = false;
    } else {
        // New option selected, update
        this.loading = true;
        try {
          try {
            await this.database.doc(`jobs/${this.currentJobId}`).update({
              'system': $event
            });
          }
          catch (err) {
            console.log(err)
            this.error = true
          }
          this.success = true;
          this.loading = false;
        } catch (err) {
          this.error = true
        }
        this.loading = false;
        this.isPresenting = false;
    }
  }

  getTotal($event) {
    if ($event != this.job.price) {
      // New option selected, update
      this.loading = true;
      try {
        this.database.doc(`jobs/${this.currentJobId}`).update({
          'price': $event,
          'balance': $event
        });
        this.success = true;
        this.loading = false;
      } catch (err) {
        this.error = true
      }
      this.loading = false;
      this.isPresenting = false;
    } else {
      this.isPresenting = false;
    }
  }

  getEnhancements($event) {
    this.loading = true;
    try {
      this.database.doc(`jobs/${this.currentJobId}`).update({
        'enhancements': $event || []
      });
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
    }
    this.loading = false;
  }

  reload() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/dartboard/all/${this.currentJobId}`], {
       queryParams: {
         filter: this.filterParam
       },
       queryParamsHandling: 'merge',
      });
    });
  }

  updateFilterParams(filter?:string) {
    this.router.navigate([], {
     queryParams: {
       filter: filter ? filter : this.filterParam
     },
     queryParamsHandling: 'merge',
    });
  }

  async generateProposalAndSendToCustomer(job,proposal) {
    this.loading = true;
    await this.customerProposalService.createNewProposalAndUpdateJob(job,proposal);
    this.loading = false;
    this.toggleMessageOpen(true)
  }

  resetSelectedProposal() {
    this.loading = true;
    try {
      this.database.doc(`jobs/${this.currentJobId}`).update({
        'system': null,
        'customerSelectedSystem': null,
        'price': null,
        'balance': null,
        'status': 'pending'
      });
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
    }
    this.loading = false;
  }

  addOnsEditDone() {
    this.saveAddOns()
    this.isAddOnsOpen = !this.isAddOnsOpen
  }

  addOnsEditCancel() {
    this.isAddOnsOpen = !this.isAddOnsOpen
    this.reload()
  }

  async saveAddOns() {
    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).set({
        'addOns' : this.job.addOns
      }, { merge: true });
    } catch (err) {
      console.log(err)
    }
  }

  toggleIsAddOnsOpen() {
    this.hasEdited = true;
    this.isAddOnsOpen = !this.isAddOnsOpen
  }

  toggleCreateCustomSystem() {
    this.isCreateCustomSystem = !this.isCreateCustomSystem
  }

  async deletePsis() {
    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).set({
        'psis' : null
      }, { merge: true });
    } catch (err) {
      console.log(err)
    }
  }

  async savePsis() {
    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).set({
        'psis' : this.job.psis
      }, { merge: true });
    } catch (err) {
      console.log(err)
    }
  }

  async getSetCustomSystem($event) {

    if (!$event) {
      this.toggleCreateCustomSystem()
    } else {

    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).set({
        'psis' : {
          meta : $event.meta ,
          brands : $event.brands,
          enhancements : $event.enhancements,
        },
        'tonnage': $event.brands[0]?.formattedOptions[0].tonnage,
        'template': '0000',
        'templateDescription.brand': $event.brands[0]?.brand,
        'templateDescription.type': 'custom',
      }, { merge: true });
    } catch (err) {
      console.log(err)
    }

      // this.jobForm.addControl( 
      //   'psis', 
      //   this.formBuilder.group({
      //     meta : this.formBuilder.group($event.meta) ,
      //     brands : this.formBuilder.array($event.brands),
      //     enhancements : this.formBuilder.array($event.enhancements)
      //   }) 
      // )

      // this.jobForm.patchValue({
      //   template: '0000',
      //   templateDescription: {
      //     brand: $event.brands[0]?.brand,
      //     type: 'custom'
      //   },
      //   tonnage: $event.brands[0]?.formattedOptions[0].tonnage,
      // })

      this.toggleCreateCustomSystem()

    }

  }

  // Custom Discounts


  editDiscount(discount) {
      this.selectedDiscount = {...discount}
    // console.log(discount)
    // if(discount.isCustomAdded) {
    //   this.addCustomDiscountsItem()
    //   this.selectedCustomDiscountsItem = {...discount}
    // } else {
    //   this.selectedDiscount = {...discount}
    // }
    // this.toggleEditDiscount(true)
  }

  toggleEditDiscount(state?:boolean) {
    if (state == false || this.isEditDiscount == true) this.selectedDiscount = false
    state != null ? this.isEditDiscount = state : this.isEditDiscount = !this.isEditDiscount
  }

  addCustomDiscountsItem() {
    this.isAddCustomDiscountsItem = true
  }


  saveDiscount(discount) {
    if (this.job?.customDiscounts) {
      if (discount?.id) {
        // Update Existing discount within the array
        const index = this.job.customDiscounts.findIndex(item => item.id == discount.id)
        this.job.customDiscounts[index] = discount;
        this.saveCustomDiscounts()
      } else {
        // Add a new item to customDiscounts
        const newDiscount = { ...discount , id: this.afs.createId() }
        this.job?.customDiscounts.push(newDiscount); 
        this.saveCustomDiscounts()
      }
    } else {
      // customDiscounts doesnt exist, create it with the new discount as the only item
      const newDiscount = { ...discount , id: this.afs.createId() }
      this.job = { ...this.job, customDiscounts : [ newDiscount ]}
      this.saveCustomDiscounts()
    }
  }

  getSavedCustomDiscount($event) {
    const discount = $event
    this.saveDiscount(discount)
    this.isAddCustomDiscountsItem = false
  }


  toggleSelectedDiscountAppliesToOptions(event, option) {
    const isChecked: boolean = event.target.checked;
    if (isChecked) {
      this.selectedDiscount.appliesToOptions.push(option)
      // Add
    } else {
      // Remove
      this.selectedDiscount.appliesToOptions = this.selectedDiscount.appliesToOptions.filter(item => item != option)
    }
  }

  // toggleCustomDiscount(cd,i,event) {
  //   this.hasEdited = true;

  //   const isChecked: boolean = event.target.checked;

  //   if (!isChecked) {
  //     // If the item was unchecked, we need to remove that addOn at its index
  //     // this.job.addOns.removeAt(i)
  //     this.job.customDiscounts = this.job.customDiscounts.filter(item => item.name != cd.name)
  //     this.saveCustomDiscounts()
  //   } else if (isChecked) {
  //     // Item was checked, we push new Add-On
  //     if (this.job?.customDiscounts) {
  //       this.job?.customDiscounts.push(cd);
  //       this.saveCustomDiscounts()
  //     } else {
  //       this.job = { ...this.job, customDiscounts : [ cd ]}
  //       this.saveCustomDiscounts()
  //     }
  //     this.saveCustomDiscounts()
  //   }

  // }

  async saveCustomDiscounts() {
    this.loading = true;
    try {
      await this.database.doc(`jobs/${this.currentJobId}`).update({
        'customDiscounts': this.job.customDiscounts
      });
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
      this.loading = false;
    }
  }

  isCustomDiscountAdded(addOn) {
    if (!this.job.customDiscounts) return false
    return this.job?.customDiscounts.some(item => item.id == addOn.id)
  }

  removeCustomDiscount(discount) {
    this.hasEdited = true;
    this.job.customDiscounts = this.job.customDiscounts.filter(item => item.id != discount.id)
    this.saveCustomDiscounts()
  }

    async deleteCD() {
    this.loading = true;
    try {
      this.database.doc(`jobs/${this.currentJobId}`).update({
        'customDiscount': null
      });
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
    }
    this.loading = false;
  }

  // discounts/rebates total
  async getSetPromos() {
    let available = [];
    let optional = []

    if (this.proposal?.discounts) {
      for await (const discount of this.proposal.discounts) {
        if (this.isDiscountApplicableToJobTonnage(discount)) {
          if (discount?.isOptional == null || discount?.isOptional == false) { 
            // Automatic Discounts
            if (this.isDiscountCurrentlyAvailable(discount)) {
              available.push(discount) // push the available discount to the array
            }
          } else {
            // Optional Discounts
            optional.push(discount) // push the available discount to the array
          }
        }
      }
    }

    this.availablePromos = available;
    this.optionalPromos = optional;
  }

  isDiscountApplicableToJobTonnage(discount) {
    if (!this.job.system) return true // if theres no system, we assume its applicable, we have to know the system to check if its applicable to that options tonnage

    if (!discount?.compatibleOptionTonnages?.length) return true // No tonnage specific details, assumed to be applicable

    const compatibleOption = discount?.compatibleOptionTonnages.find(item => item.id == this.job?.system?.option)
    if (!compatibleOption) return false // Option isnt compatible at all, return false

    const jobTonnage = parseFloat(this.job.tonnage)
    const isExcluded = compatibleOption?.excludedTonnages.includes(jobTonnage)

    if (isExcluded) {
      return false // job tonnage is excluded
    } else {
      return true // job tonnage is not in excluded tonnages list, its applicable
    }
  }

  isDiscountCurrentlyAvailable(discount) {
    const today = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    if (discount?.enabledDate && discount?.expirationDate) {
      return this.dateCalculationsService.isDateWithinRange(today,discount.enabledDate,discount.expirationDate)
    } else if (discount?.enabledDate == null && discount?.expirationDate == null) {
      return true
    } else if (discount?.enabledDate && !discount?.expirationDate){
      return this.dateCalculationsService.isDateGreaterThan(today,discount.enabledDate)
    } else if (discount?.expirationDate && !discount?.enabledDate ){
      return this.dateCalculationsService.isDateLessThan(today,discount.expirationDate)
    } else {
      return true
    }
  }

  // Addons

  toggleAddOn(addOn,i,event) {
    this.hasEdited = true;
    const newAddOn = {
      name: addOn.name,
      price: addOn.price,
      quantity: 1
    };

    const isChecked: boolean = event.target.checked;

    if (!isChecked) {
      // If the item was unchecked, we need to remove that addOn at its index
      // this.job.addOns.removeAt(i)
      this.job.addOns = this.job.addOns.filter(item => item.name != addOn.name)
    } else if (isChecked) {
      // Item was checked, we push new Add-On
      this.job.addOns.push(newAddOn);
    }

  }

  isAddOnActive(addOn) {
    return this.job.addOns.some(item => item.name == addOn.name)
  }

  removeAddOn(i) {
    this.hasEdited = true;
    this.job.addOns.splice(i,1);
  }

  async getSetAddOnPrice(i) {
    const recommendedPrice = await this.adminPricingService.getRecommendedItemPrice(this.job.addOns[i].c,this.job.addOns[i].h,this.job.addOns[i].p)
    this.job.addOns[i].price = recommendedPrice
  }

  removeEnhancement(i) {
    this.hasEdited = true;
    this.job?.enhancements?.splice(i,1);
  }

  toggleIsCutsheetCollapsed() {
    this.isCutsheetCollapsed = !this.isCutsheetCollapsed;
  }

  routeToLinkedView() {
    this.router.navigate([`/proposals/linked/${this.currentJobId}`])
  }

  toggleIsPresenting() {
    this.isPresenting = !this.isPresenting
  }

  toggleIsEditing(state?) {
    if(state) {
      this.isEditing = state
    } else {
      this.isEditing = !this.isEditing
    }
    this.isCutsheetCollapsed = false;
  }

  toggleIsEditingCutsheet(state?) {
    if(state) {
      this.isEditingCutsheet = state
    } else {
      this.isEditingCutsheet = !this.isEditingCutsheet
    }
    this.isCutsheetCollapsed = false;
  }

  // Images

  toggleViewImages() {
    this.isViewImages = !this.isViewImages
  }

  async onFileSelected(event) {
    let path : string = `jobs/${this.currentJobId}/cutsheet/images/${this.afs.createId()}`;
    let imagePaths = [...this.job.cutsheet.imagePaths, path];
    const config = {
      file: event.target.files[0],
      maxSize: 750
    };
    const resizedImage = await this.resizeImage(config)
    try {
      await this.uploadResizedImage(resizedImage,path, imagePaths)
    } catch(err) {
      console.log(err)
    }
  }

  async resizeImage(settings: IResizeImageOptions) {
    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = (dataURI: string) => {
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], {type:mime});
    };
    const resize = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
          if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
          }
      } else {
          if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
          }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL('image/jpeg');
      return dataURItoBlob(dataUrl);
    };

    return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }

        reader.onload = (readerEvent: any) => {
          image.onload = () => ok(resize());
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    })
  };

  uploadResizedImage(file, path, imagePaths) {
    const ref = this.storage.ref(path);

    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize( async() =>  {
        this.loading = true;
         this.downloadURL = await ref.getDownloadURL().toPromise().catch(
            err =>  {
              alert(`There was an error with your upload, or it is taking longer than expected. Please upload cutsheet images from the job details at a later time.`)
              console.log(err)
              // this.setUpForOfflineSave()
              this.resetUpload();
              this.loading = false;
            }
          );
        this.job.cutsheet.images.push(
          this.downloadURL
        );
        try {
          await this.database.doc(`jobs/${this.currentJobId}`).update(
            {
              'cutsheet.images' : this.job.cutsheet.images,
              'cutsheet.imagePaths' : imagePaths
            }
          );
          this.success = true;
          this.loading = false;
          this.waitThenResetUpload();
        } catch (err) {
          this.error = true
        }
        this.isEditing = false;
        this.hasEdited = false;
        this.loading = false;
        // this.jobForm.updateValueAndValidity()
        // this.jobForm.markAsDirty()
      }),
    );
  }

  resetUpload() {
    this.task = null;
    this.percentage = null;
    this.snapshot = null;
    this.downloadURL = null;
    if(this.cutsheetImageUpload) {
      this.cutsheetImageUpload.nativeElement.value = ""
    }
  }

  waitThenResetUpload() {
    setTimeout(() => {
      this.resetUpload()
    }, 1000);
  }

  onSubmit() {
    this.submitHandler();
  }

  async updateFinancingAfterRemovingDeposit(balanceAfterRemovingDeposit) {
    let pmt;
    let fin = this.job?.confirmation?.selectedFinancingDetails?.selectedFinancingOption;
    let b = balanceAfterRemovingDeposit;
    let p = balanceAfterRemovingDeposit;
    // let p = balanceAfterRemovingDeposit / (1 - (fin.fee / 100));

    let finr = fin.apr / 1200; // divide by 100 to convert to decimal and by 12 to get monthly rate

    if (fin.apr == 0 && !fin.min) {
      pmt = p / fin.months;
    } else if (!fin.min) {
      pmt = p * finr * Math.pow(1 + finr,fin.months) / (Math.pow(1 + finr, fin.months) - 1);
    } else if (fin.min) {
      pmt = p * fin.min / 100;
    }

    const pmtFormatted = parseFloat(this.decimalPipe.transform(pmt, '0.2-2'))
    let financePaymentTotal = pmtFormatted * fin.months;
    let financedAmount = p;

    let updatedSelectedFinancingDetails = {
      'financingBasisPrice' : b,
      'financePayment' : pmt,
      'financePaymentsTotalAtTermEnd': financePaymentTotal,
      'financedAmount': financedAmount,
    }

    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).set({
        'confirmation': {
          'selectedFinancingDetails' : updatedSelectedFinancingDetails
        }
      }, { merge: true });
    } catch (err) {
      console.log(err)
    }

  }

  async reapplyPreviousDeposits() {
    if (this.job.deposits || this.job.depositsTotal) {
      const recalculatedDepositsTotal = this.job?.deposits.reduce(
        (total : number, deposit) => {
          return total += deposit.adjustedAmount
        }, 0
      );
      await recalculatedDepositsTotal;
      let balanceAfterReapplyingDeposit = this.job.price - recalculatedDepositsTotal;
      if (balanceAfterReapplyingDeposit != this.job.balance || recalculatedDepositsTotal != this.job.depositsTotal ) {
        try {
          this.loading = true
          await this.database.doc(`jobs/${this.currentJobId}`).update({
            "balance" : balanceAfterReapplyingDeposit,
            "depositsTotal" : recalculatedDepositsTotal
          });
          this.loading = false
        } catch(err) {
          alert(err)
        }
      }

    }
  }

  async removeDeposit(i) {

    if (this.job?.meta?.jobsGroupId) {
      this.loading = true;
      await this.linkedJobsComponent.removeDepositOnAllLinkedJobs(i);
      this.loading = false;
      return
    }
    
    this.loading = true;
    if (this.job.deposits.length == 1) {
      try {
        await this.database.doc(`jobs/${this.currentJobId}`).update({
          "deposits": null,
          "balance" : this.job.price,
          "depositsTotal": null
        });
        if (this.job?.confirmation?.paymentMethod == 'financing') {
          await this.updateFinancingAfterRemovingDeposit(this.job.price);
        }
        this.success = true;
        this.loading = false;
      } catch (err) {
        this.error = true;
        this.loading = false;
      }
    } else {
      try {
        // Remove the deposit and update DB
        const edited = this.job.deposits.splice(i,1);
        await edited;
        await this.database.doc(`jobs/${this.currentJobId}`).update({
          "deposits": this.job.deposits
        });
        try {
          const depositsTotalAfterRemoval = this.job.deposits.reduce(
            (total : number, deposit) => {
              return total += deposit.adjustedAmount
            }, 0
          );
          await depositsTotalAfterRemoval;
          let balanceAfterRemovingDeposit = this.job.price - depositsTotalAfterRemoval;
          await balanceAfterRemovingDeposit;
          await this.database.doc(`jobs/${this.currentJobId}`).update({
            "balance" : balanceAfterRemovingDeposit,
            "depositsTotal" : depositsTotalAfterRemoval
          });
          if (this.job?.confirmation?.paymentMethod == 'financing') {
            await this.updateFinancingAfterRemovingDeposit(balanceAfterRemovingDeposit);
          }
          this.success = true;
          this.loading = false;
        } catch (err) {
          this.error = true;
          this.loading = false;
        }
      } catch (err) {
        this.error = true;
        this.loading = false;
      }
    }

  }

  async autoSaveCutsheet(cutsheetForm) {
    if(cutsheetForm.dirty && cutsheetForm.valid) {
      try {
        this.savingCutsheet = true;
        await this.database.doc(`jobs/${this.currentJobId}`).update({
          cutsheet: this.job.cutsheet
        });
        this.success = true;
        this.savingCutsheet = false;
      } catch (err) {
        this.error = true
      }
    }


    // if (editableFieldsForm.valid && editableFieldsForm.dirty) {
    //   console.log('field is valid, and dirty, autosave....')
    //   try {
    //     await this.database.doc(`jobs/${this.currentJobId}`).update(this.job);
    //     this.success = true;
    //     this.loading = false;
    //   } catch (err) {
    //     this.error = true
    //   }
    // } else {
    //   console.log('not saving...')
    // }
  }

  async onUpdateDraft() {
    await this.submitHandler();
    this.isDraft = false;
  }

  async submitHandler() {
    this.loading = true;
    try {
      await this.database.doc(`jobs/${this.currentJobId}`).update(this.job);
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
    }
    this.isEditing = false;
    this.hasEdited = false;
    this.loading = false;
  }

  async confirmUnsign() {
    this.isConfirmUnsign = true;
  }

  toggleIsConfirmUnsign(state:boolean) {
    if(state) {
      this.isConfirmUnsign = state
    } else {
      this.isConfirmUnsign = !this.isConfirmUnsign
    }
  }

  async unsign() {
    this.loading = true;
    try {
      await this.database.doc(`jobs/${this.currentJobId}`).update({
        "status": 'pending',
        "price": null,
        "balance": null,
        'confirmation': null
      });
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
    }
    this.isEditing = false;
    this.hasEdited = false;
    this.loading = false;
  }

  async resetEnhancements() {
    this.job.enhancements = [];
  }

  // async saveStatus() {
  //   this.loading = true;
  //   try {
  //     await this.database.doc(`jobs/${this.currentJobId}`).update({
  //       "status": this.job.status
  //     });
  //     this.success = true;
  //     this.loading = false;
  //     this.updateFilterParams(this.job.status);
  //     this.router.navigate([`/dartboard/all/${this.currentJobId}`], {
  //      queryParams: {
  //        filter: this.job.status
  //      },
  //      queryParamsHandling: 'merge',
  //     });
  //   } catch (err) {
  //     this.error = true
  //   }
  //   this.isEditing = false;
  //   this.hasEdited = false;
  //   this.loading = false;
  // }

  async saveNotes() {
    this.loading = true;
    try {
      await this.database.doc(`jobs/${this.currentJobId}`).update({
        "notes": this.job.notes
      });
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
    }
    this.isEditing = false;
    this.hasEdited = false;
    this.loading = false;
  }

  async saveCutsheetNotes() {
    this.loading = true;
    try {
      await this.database.doc(`jobs/${this.currentJobId}`).update({
        cutsheet: {
          "notes": this.job.notes
        }
      });
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
    }
    this.isEditing = false;
    this.hasEdited = false;
    this.loading = false;
  }

  async saveJobField(field, value) {
    this.loading = true;
    try {
      await this.database.doc(`jobs/${this.currentJobId}`).update({
        [field] : this.job[value]
      });
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.error = true
    }
    this.isEditing = false;
    this.hasEdited = false;
    this.loading = false;
  }

  async deleteJob() {
    const job = this.job;
    this.loading = true;
    try {
      // Add job to other archivedJobs array
      await this.database.doc(`archivedJobs/${this.currentJobId}`).set(job);
      // Delete the job from the "active" jobs
      await this.database.doc(`jobs/${this.currentJobId}`).delete();
      this.success = true;
      this.loading = false;
      this.router.navigate(["/dartboard/all"], {
       queryParams: {
         filter: this.filterParam
       },
       queryParamsHandling: 'merge',
      });
    } catch (err) {
      this.error = true
    }
    this.loading = false;

  }

  addCustomAddOn() {
    const newAddOn = {
      name: null,
      price: null,
      quantity: 1,
      isCustom: true,
    };
    this.job.addOns.push(newAddOn);
  }

  removeCustomAddOn(addOn) {
    this.job.addOns.removeAt(this.job.addOns.findIndex(item => {
      item.name == addOn.name && item.price == addOn.price
    }))
  }

  async markJobAsLost() {
    this.loading = true;
    try {
      await this.database.doc(`jobs/${this.currentJobId}`).update({
        'status' : 'lost'
      });
      this.success = true;
      this.loading = false;
      this.router.navigate(["/dartboard/all"], {
       queryParams: {
         filter: this.filterParam
       },
       queryParamsHandling: 'merge',
      });
    } catch (err) {
      this.error = true
    }
    this.isEditing = false;
    this.hasEdited = false;
    this.loading = false;
    this.router.navigate(["/dartboard/all"], {
     queryParams: {
       filter: this.filterParam
     },
     queryParamsHandling: 'merge',
    });
  }

  trackByFn(index, item) { return index; }
  
  ngOnDestroy() {
    this.routeSub.unsubscribe()
    this.userSub.unsubscribe()
    this.proposalTemplateSub?.unsubscribe()
    this.proposalTemplatesSub?.unsubscribe()
    this.oldProposalTemplateSub?.unsubscribe()
    this.jobSub?.unsubscribe()
    this.queueToggleService.toggleQueueState(true)

  }

}
