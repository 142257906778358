import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { UsersComponent } from './users/users.component';
import { SurveysComponent } from './surveys/surveys.component';
import { BrandingComponent } from './branding/branding.component';
import { PricingComponent } from './pricing/pricing.component';
import { ProductsComponent } from './products/products.component';
import { AddOnsComponent } from './add-ons/add-ons.component';
import { PromotionComponent } from "./promotions/promotion/promotion.component";
import { PromotionsComponent } from "./promotions/promotions.component";
import { FilesComponent } from './files/files.component';
import { ArchivesComponent } from './archives/archives.component';
import { FeaturesComponent } from './features/features.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CutsheetComponent } from './cutsheet/cutsheet.component';
import { PricingToolComponent } from './shared/pricing-tool/pricing-tool.component';
import { FollowUpsComponent } from './follow-ups/follow-ups.component';



@NgModule({
  declarations: [AdminComponent, UsersComponent, SurveysComponent, BrandingComponent, PricingComponent, ProductsComponent, AddOnsComponent,PromotionsComponent, PromotionComponent, FilesComponent, ArchivesComponent, FeaturesComponent, CutsheetComponent, PricingToolComponent, FollowUpsComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    DragDropModule
  ]
})
export class AdminModule { }
