import { Injectable, OnDestroy  } from '@angular/core';
import { Observable, Subscription, of } from "rxjs";
import { tap , take, shareReplay, map} from 'rxjs/operators';
import { FirestoreService } from '@shared/services/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';

export interface Template {
  id? : string,
  name?: string,
  type: string,
  status? : string,
  brands? : Brand[],
  enhancements? : any[],
  discounts? : any[],
  tonnages? : any[],
  files? : any[],
  meta: {
    createdAt: {
      seconds: number,
      nanoseconds: number
    }
    createdBy: string;
  }

}

export interface Brand {
  brand: string,
  options? : Option[],
  positions? : any[],
  labels? : {
    columns: any[],
    rows: any[]
  }
}
export interface Option {
  id?: string;
  details: {
    outdoorUnit: string;
    outdoorUnitBrochure?: string;
    indoorUnit: string;
    indoorUnitBrochure?: string;
    thermostat: string;
    thermostatBrochure?: string;
    warranty?: string;
    hoursToInstall: number;
    materialCost: number;
    netProfit?: number;
  },
  features? : any[],
  sizes?: Tonnage[],
  links?: any[],
  meta?: {
    brandsIndex?: string,
    optionsIndex?: string,
    positionsIndex?: string,
    row?: string,
    column?: string,
  }
}

export interface Tonnage {
  isEnabled: boolean;
  tons: number;
  seer: number;
  cost: number;
  cashPrice: number;
}

export interface SelectedSystem {
    option: string,
    brand? : string,
    hoursToInstall: number,
    indoorUnit: string,
    indoorUnitBrochure?: string,
    materialCosts: string,
    netProfit?: string,
    outdoorUnit: string,
    outdoorUnitBrochure?: string,
    thermostat: string,
    thermostatBrochure?: string,
    warranty?: string,
    features?: any[],
    comparisonFeatures?: any[],
    tonnage: string,
    cashPrice: number,
    seer: number,
    db?: number,
    hspf?: number,
    afue?: number,
    cost: number,
    label?: string,
    rebates?: any[],
    rebatesTotal?: number,
    links?: any[],
    meta: {
      brandsIndex?: string,
      optionsIndex?: string,
      positionsIndex?: string,
      row?: string,
      column?: string,
    }
    isHiddenForCustomer?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TemplatesService implements OnDestroy {
  templatesAllSub: Subscription;
  templates$: Observable<any[]> = of([]);
  templatesAll$: Observable<any[]> = of([]);

  activeTemplates$ : Observable<any[]> = of([]);

  templates = [];

  constructor(
    private afs : FirestoreService,
    private db : AngularFirestore
  ) {

    //TODO - read templates all once, then filter to set the other two instead of reading again?

    this.templatesAll$ = this.afs.col$('templates')
    .pipe( shareReplay({bufferSize: 1, refCount: true}));

    this.templates$ = this.templatesAll$.pipe( 
      shareReplay({bufferSize: 1, refCount: true}),
      map( 
        (templates) => templates.filter(template => template.status == 'active')
      )
      
    );

    // this.templates$ = this.afs.col$('templates', ref => ref
    //   .where('status', '==', 'active')
    // ).pipe( shareReplay({bufferSize: 1, refCount: true}) );

    this.templatesAllSub = this.templatesAll$.subscribe(
      templates => this.templates = templates
    ), shareReplay({bufferSize: 1, refCount: true})
  }


  getTemplate$(id:string) {
    // const template = this.afs.doc$('templates/' + id);
    const template = this.templates.find(template => template.id == id);
    if (template) {
      return of(template);
    } else {
      console.error(`No template$ found with ID ${id}. It may have been deleted. Please notify database admin.`)
      return of(false)
    }
  }

  ngOnDestroy() {
    this.templatesAllSub.unsubscribe()
  }

  public watchTemplate$(id:string) {
    return this.templatesAll$.pipe(
      shareReplay({bufferSize: 1, refCount: true}),
      map( 
        (templates) => templates.find((template) => template.id === id)
      )
    )
  }

  async removeExpiredDiscountFromTemplate(templateId,discount) {
    console.log('removeExpiredRebateFromTemplate',templateId,discount)
    // try {
    //   this.afs.doc(`templates/${templateId}`).update({
    //     discounts : null
    //   })
    // } catch (err) {
    //   console.log(err)
    // }

  }


}
