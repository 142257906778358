import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FollowUpService } from '@core/follow-up/services/follow-up.service';
import { FirestoreService } from '@shared/services/firestore.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-follow-ups',
  templateUrl: './follow-ups.component.html',
  styleUrls: ['./follow-ups.component.scss']
})
export class FollowUpsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  loading: boolean = true;
  columnsSub: Subscription;

  defaultColumns = [ 
  { state: "1", label: "Follow-up 1", isVisible: false },
  { label: "Follow-up 2", state: "2", isVisible: false },
  { label: "Follow-up 3", isVisible: false, state: "3" },
  { isVisible: false, label: "Follow-up 4", state: "4" },
  { state: "5", label: "Follow-up 5", isVisible: false },
  { isVisible: false, label: "Follow-up 6", state: "6" },
  { label: "Follow-up 7", state: "7", isVisible: false },
  { state: "8", isVisible: false, label: "Follow-up 8" },
  { state: "9", label: "Follow-up 9", isVisible: false },
  { isVisible: false, label: "Follow-up 10", state: "10" }
]

  constructor(
    private formBuilder: FormBuilder,
    public database: FirestoreService,
    private afs: AngularFirestore,
    private followUpService: FollowUpService

  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      columns: this.formBuilder.array([])
    })

    this.columnsSub = this.followUpService.columns$.subscribe(
      (data: any[]) => {
        this.loading = true,
        this.columns.clear(),
        (data?.length ? data : this.defaultColumns).map( obj =>
          this.columns.push(
            new FormGroup({
              label: new FormControl( obj['label'], Validators.required ),
              isVisible: new FormControl( obj['isVisible'] ),
              state: new FormControl( obj['state'] ),
            })
          )
        ),
        this.loading = false
      }
    )
  }

  ngOnDestroy(): void {
      this.columnsSub?.unsubscribe()
  }

  get columns() {
    return this.form.get("columns") as FormArray;
  }

  onSubmit() {
    if (this.form.invalid) return
    this.save()
  }

  async save() {
    this.loading = true;
    const formValue = this.form.value;
    try {
      await this.afs.doc("admin/follow-ups").set(formValue, { merge: true });
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

}
