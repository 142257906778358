import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Template, SelectedSystem } from "@shared/services/templates.service";
import { ProposalPricingService } from '@shared/services/proposal-pricing-service';
import { AuthService } from '@shared/services/auth.service';
import { AdminPricingService } from '@shared/services/admin-pricing.service';
import { FirestoreService } from '@shared/services/firestore.service';

@Component({
  selector: 'app-formatted-system-option-card',
  templateUrl: './formatted-option-card.component.html',
  styleUrls: ['./formatted-option-card.component.scss'],
  host: {
    class: "card "
  }
})
export class FormattedOptionCardComponent implements OnInit {

  @Output() systemSelected = new EventEmitter<object>();

  @Input() system : SelectedSystem;
  @Input() isCustomer: boolean;
  @Input() job;
  @Input() psis;
  @Input() paymentOption;
  @Input() selectedFinancingOption;
  systemEstMonthlyPayment: number;
  displayPrice : number;

  constructor(
    private proposalPricingService : ProposalPricingService,
    public adminPricingService : AdminPricingService,
    public authService : AuthService,
    
  ) { }

  ngOnInit(): void {
    // this.getEstMonthlyPayment();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedFinancingOption']?.currentValue != changes['selectedFinancingOption']?.previousValue) {
      this.getEstMonthlyPayment();
    }
  }

  selectSystem() {
    if (this.system && !this.system.isHiddenForCustomer) {
      this.systemSelected.emit(this.system)
    }
  }

  async getSetDisplayPrice() {
    this.displayPrice = this.proposalPricingService.getDisplayedPrice(this.system.cashPrice, this.psis)
  }


  async getEstMonthlyPayment() {
    await this.getSetDisplayPrice()

    let fin = this.selectedFinancingOption;

    if (!fin) return null;
    
    const b = this.displayPrice;
    const p = b; // p set to straight price, cant add fees anymore
    const finr = fin.apr / 1200; // divide by 100 to convert to decimal and by 12 to get monthly rate

    // 0% APR and equal payments
    if (fin.apr == 0 && !fin.min) {
      this.systemEstMonthlyPayment = p / fin.months;

    // Not 0% APR but equal payments
    } else if (!fin.min) {
      this.systemEstMonthlyPayment = p * finr * Math.pow(1 + finr,fin.months) / (Math.pow(1 + finr, fin.months) - 1);

    // Non-equal payments (ability to make smaller minimum payments)
    } else if (fin.min) {
      this.systemEstMonthlyPayment = p * fin.min / 100;
    }
  }


}
