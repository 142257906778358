import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, NgForm } from "@angular/forms";
import { AuthService } from '@shared/services/auth.service';
import { TemplatesService } from '@shared/services/templates.service';
import { ProductsService } from '@shared/services/products.service';
import { AddOnService } from "@shared/services/add-on.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, Subscription, timer , of } from "rxjs";
import { finalize, take } from 'rxjs/operators';
import { FirestoreService } from '@shared/services/firestore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyService } from "@shared/services/survey.service";
import { LeadsService } from '@shared/services/leads.service';
import { CutsheetService } from '@shared/services/cutsheet.service';
import { AdminPricingService } from '@shared/services/admin-pricing.service';

interface IResizeImageOptions {
  maxSize: number;
  file: File;
}

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit, OnDestroy {

  @ViewChild('proposalForm') proposalForm : NgForm;
  
  routeSub: Subscription;
  queryParamsSub: Subscription;

  isOnline : boolean = true;

  elapsedTime : number = 1;

  jobForm: FormGroup;
  isCutsheetOpen: boolean = false;
  isAddOnsOpen: boolean = false;
  currentUser;
  currentJobId: string;

  createFromJob;
  isCreateFromJobUpdateNeeded : boolean = false; 

  hasSearched: boolean = false;
  filteredType : string = null;
  filteredProposals : Observable<any[]>;
  filteredMultiBrandTemplates: Observable<any[]>;

  loading: boolean = false;
  success: boolean = false;
  error: boolean = false;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  isCreateCustomSystem: boolean = false;
  isResettingForm: boolean = false;

  @ViewChild('cutsheetImageUpload')
  cutsheetImageUpload: ElementRef;
  cutsheetImageUploadValue;

  phonePattern = "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$";

  timerSub: Subscription
  userSub: Subscription
  cutsheetSub: Subscription
  leadSub: Subscription

  constructor(
    private formBuilder : FormBuilder,
    private authService : AuthService,
    public productsService : ProductsService,
    public addOnService : AddOnService,
    private afs : FirestoreService,
    private db : AngularFirestore,
    public router : Router,
    private storage: AngularFireStorage,
    private surveyService : SurveyService,
    public templatesService : TemplatesService,
    public leadsService : LeadsService,
    private route: ActivatedRoute,
    public cutsheetService : CutsheetService,
    public adminPricingService: AdminPricingService
  ) {

    this.currentJobId = this.db.createId()

    this.jobForm = this.formBuilder.group({
      meta: this.formBuilder.group({
        isAdditionalJob: false,
        jobsGroupId: '',
        createdBy: null,
        created: Date.now(),
        id: '',
        timesheet : {
          started: null,
          saved: null,
          minutes : null
        }
      }),
      state: [ '0' ],
      status: [ 'pending' ],
      price: [ null ],
      info: this.formBuilder.group({
        project: [ null ],
        isSystemWorking: [ null ],
        firstName: [ null, Validators.required],
        lastName: [ null, Validators.required],
        phone: [ null, [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),Validators.required]],
        email: [ null,
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
          ]
        ],
        address1: [ null, Validators.required],
        address2: [ null],
        city: [ null, Validators.required],
        state: [ null, Validators.required],
        zip: [ null, Validators.required],
      }),
      surveyQuestions: this.formBuilder.array([]),
      cutsheet : this.formBuilder.group({
        images: this.formBuilder.array([]),
        imagePaths: this.formBuilder.array([]),
        notes: [ null ],
      }),
      addOns: this.formBuilder.array([]),
      template: [ null, Validators.required ],
      templateDescription: this.formBuilder.group({
        brand: null,
        type: null
      }),
      tonnage: [ null, Validators.required ],
      customDiscount: this.formBuilder.group({
        createdBy : [ null ],
        amount: [ null ]
      }),
      enhancements: this.formBuilder.array(
        []
      )
    })



    this.surveyService.surveyQuestions$.pipe(take(1)).subscribe(
      survey => (
        this.surveyQuestions.clear(),
        survey.questions.map(
          obj => (
            this.surveyQuestions.push(
              new FormGroup({
                question: new FormControl( obj['question'] ),
                answer: new FormControl( obj['answer'] ),
              })
            )
          )
        )
      )
    );

  }


  log() {
    // console.log(this.addOns.controls)
  }

  ngOnInit(): void {

    this.queryParamsSub = this.route.queryParams.subscribe( queryParams => {
      if (this.route.snapshot.queryParamMap.get('createFromJob') || 0) {
        this.createFromJob = this.route.snapshot.queryParamMap.get('createFromJob');
        if (this.createFromJob) {
          this.afs.doc$(`jobs/${this.createFromJob}`).pipe(take(1)).subscribe((doc : any) => {
            if (doc) {

              if (doc.meta?.jobsGroupId) {
                this.jobForm.get('meta').patchValue({
                  jobsGroupId: doc.meta?.jobsGroupId
                })
              } else {
                this.isCreateFromJobUpdateNeeded = true;
                this.jobForm.get('meta').patchValue({
                  jobsGroupId: this.db.createId()
                })
              }
       
              const info = {
                firstName: doc?.info?.firstName || null,
                lastName: doc?.info?.lastName || null,
                phone: doc?.info?.phone || null,
                email: doc?.info?.email || null,
                address1: doc?.info?.address1 || null,
                address2: doc?.info?.address2 || null,
                city: doc?.info?.city || null,
                state: doc?.info?.state || null,
                zip: doc?.info?.zip || null,
              }
              // if this doc exists and has data, patch that data into the form
              // this.jobForm.get('meta').patchValue(meta)
              this.jobForm.get('info').patchValue(info)
            } else {
              console.log(`job with id ${this.createFromJob} cannot be found`)
            }
          })
        }
      }
    })

    this.routeSub = this.route.params.subscribe( params => {
      if(this.route.snapshot.queryParamMap.get( 'lead') || 0 ) {
        // if a lead (id) param was passed into the url, we need to get that ID, check the DB and patch the data to the form
        let lead = this.route.snapshot.queryParamMap.get('lead');
        if (lead) {
          this.leadSub = this.afs.doc$(`leads/${lead}`).pipe(take(1)).subscribe((doc : any) => {
            if (doc) {
              // if this doc exists and has data, patch that data into the form
              this.jobForm.get('info').patchValue(doc.info)
            } else {
              console.log(`lead with id ${lead} cannot be found`)
            }
          })
        }

      } else if (this.route.snapshot.queryParamMap.get( 'event') || 0) {
        let event = this.route.snapshot.queryParamMap.get('event');
        if (event) {
          this.leadSub = this.afs.doc$(`appointments/${event}`).subscribe((doc : any) => {
            if (doc) {
              const info = {
                isSystemWorking: doc?.isSystemWorking || null,
                firstName: doc?.lead_firstName || null,
                lastName: doc?.lead_lastName || null,
                phone: doc?.lead_phone || null,
                email: doc?.lead_email || null,
                address1: doc?.lead_address1 || null,
                address2: doc?.lead_address2 || null,
                city: doc?.lead_city || null,
                state: doc?.lead_state || null,
                zip: doc?.lead_zip || null,
              }

              // if this doc exists and has data, patch that data into the form
              this.jobForm.get('info').patchValue(info)
            } else {
              console.log(`event with id ${event} cannot be found`)
            }
          })
        }
      }
    });

    const minutes = timer(0, 60000);
    this.timerSub = minutes.subscribe(x => this.elapsedTime = x);
    // Set created by from auth service user, and go ahead and patch in the generated id
    this.userSub = this.authService.user$.pipe(take(1)).subscribe(
      user => {
        this.currentUser = user;
        this.jobForm.get('meta').patchValue({
          createdBy: this.currentUser.uid,
          id: this.currentJobId
        })
      }

    )

    this.cutsheetSub = this.cutsheetService.cutsheet$.pipe(take(1)).subscribe(data => {
      this.setUp(data)
    })

    this.isOnline = navigator.onLine;

    if (!navigator.onLine) {
      this.setUpForOfflineSave()
    }

    window.addEventListener('online', () => {
      this.isOnline = true;
    });

    window.addEventListener('offline', () => {
      alert(`Internet connection was lost. Some things like image upload will be disabled. This job will be saved as a draft that you can finish when you regain connection.`)
      this.isOnline = false;
      this.setUpForOfflineSave()
    });

  }

  setHasSearched(state:boolean) {
    this.hasSearched = state;
  }

  setUpForOfflineSave() {

    // Cant search templates
    this.jobForm.get('template').clearValidators()
    this.jobForm.get('template').updateValueAndValidity()


    this.jobForm.updateValueAndValidity()
  }

  getTemplates(tonnage:string, type:string) {
    this.setHasSearched(true);
    this.filteredProposals = null; // reset to empty and only show new;
    this.filteredMultiBrandTemplates = this.afs.col$('templates', ref => ref
      .where('type', '==', type)
      .where('tonnages', 'array-contains', parseFloat(tonnage))
      .where('status', '==', 'active')
    );
  }

  isTemplateCompatibleWithTonnage(tonnage,templateTonnages) {
    return templateTonnages.some(item => item == tonnage)
  }


  applyTemplate(template) {
    this.jobForm.get('template').patchValue(template.id)
    this.jobForm.get('templateDescription').patchValue({
      brand: 'multi',
      type: template.type
    })
  }

  // Cutsheet

  toggleIsCutsheetOpen(state? : boolean) {
    if(state) { this.isCutsheetOpen = state }
    else {
      this.isCutsheetOpen = !this.isCutsheetOpen
    }
  }

  toggleIsAddOnsOpen(state? : boolean) {
    if(state) { this.isAddOnsOpen = state }
    else {
      this.isAddOnsOpen = !this.isAddOnsOpen
    }
  }

    async setUp(data) {
    this.loading = true;
    await this.buildFormFromObject(data)
    this.loading = false;
  }

  async buildFormFromObject(customCutsheet) {
    customCutsheet.forEach( (group, i) => {
      let groupName = group.groupName.toLowerCase();
      this.cutsheet.addControl( groupName, this.formBuilder.group({}) )
      let targetGroup = this.cutsheet.get(groupName) as FormGroup;
      group.fields.forEach(field => {
        targetGroup.addControl( field.label , new FormControl())
      })
    });
  }

  toggleCreateCustomSystem() {
    this.isCreateCustomSystem = !this.isCreateCustomSystem
  }

  getSetCustomSystem($event) {

    if (!$event) {
      this.toggleCreateCustomSystem()
    } else {
      this.jobForm.addControl( 
        'psis', 
        this.formBuilder.group({
          meta : this.formBuilder.group($event.meta) ,
          brands : this.formBuilder.array($event.brands),
          enhancements : this.formBuilder.array($event.enhancements)
        }) 
      )

      this.jobForm.patchValue({
        template: '0000',
        templateDescription: {
          brand: $event.brands[0]?.brand,
          type: 'custom'
        },
        tonnage: $event.brands[0]?.formattedOptions[0].tonnage,
      })

      this.toggleCreateCustomSystem()

    }

  }

  // Survey Questions

  get surveyQuestions() {
    return this.jobForm.get("surveyQuestions") as FormArray;
  }

  // addOns

  get addOns() {
    return this.jobForm.get("addOns") as FormArray;
  }


  get cutsheet() {
    return this.jobForm.get("cutsheet") as FormGroup;
  }

  get cutsheetImages() {
    return this.jobForm.get("cutsheet.images") as FormArray;
  }

  get cutsheetImagePaths() {
    return this.jobForm.get("cutsheet.imagePaths") as FormArray;
  }

  get template() {
    return this.jobForm.get("template") as FormControl;
  }

  toggleAddOn(addOn,i,event) {
    const newAddOn = new FormGroup({
      name: new FormControl( addOn.name, Validators.required),
      price: new FormControl( addOn.price, Validators.required),
      quantity: new FormControl( 1, Validators.required),
    });
    const isChecked: boolean = event.target.checked;

    if (!isChecked) {
      this.addOns.removeAt(this.addOns.value.findIndex(item => {
        item.name == addOn.name && item.price == addOn.price
      }))
      this.addOns.updateValueAndValidity();

    } else if (isChecked) {
      // Item was checked, we push new Add-On
      this.addOns.push(newAddOn);
      this.addOns.updateValueAndValidity();
    }
  }

  isAddOnActive(addOn) {
    return this.jobForm.value.addOns.some(item => {
      item.name == addOn.name && item.price == addOn.price
    })
  }

  addCustomAddOn() {
    const newAddOn = new FormGroup({
      name: new FormControl( null, Validators.required),
      price: new FormControl( null, Validators.required),
      quantity: new FormControl( 1, Validators.required),
      isCustom: new FormControl( true ),
      c: new FormControl( null ),
      h: new FormControl( null ),
      p: new FormControl( null ),
    });
    this.addOns.push(newAddOn);
  }

  async getSetAddOnPrice(i:number) {
    const target = this.jobForm.get(`addOns.` + i) as FormGroup;
    const value = target.value
    const recommendedPrice = await this.adminPricingService.getRecommendedItemPrice(value.c,value.h,value.p)
    target.patchValue( {
      price: recommendedPrice,
    })
  }

  removeCustomAddOn(addOn) {
    this.addOns.removeAt(this.addOns.value.findIndex(item => {
      item.name == addOn.name && item.price == addOn.price
    }))
    this.addOns.updateValueAndValidity();
  }

  offlineFileReset() {
    setTimeout(() => {
      this.resetUpload();
      this.loading = false;
    }, 2000);
  }

  resetUpload() {
    this.loading = false;
    this.success = false;
    this.task = null;
    this.percentage = null;
    this.snapshot = null;
    this.downloadURL = null;
    this.cutsheetImageUploadValue = '';
    if(this.cutsheetImageUpload) {
      this.cutsheetImageUpload.nativeElement.value = ""
    }
  }

  waitThenResetUpload() {
    setTimeout(() => {
      this.resetUpload()
    }, 1000);
  }


  // Form as a whole

  markFormAsTouched() {
    this.jobForm.markAllAsTouched()
  }

  onSubmitDraft() {
    this.jobForm.get('meta.timesheet').setValue({
      started: this.jobForm.value.meta.created,
      saved: Date.now(),
      minutes: this.elapsedTime
    });
    this.submitDraftHandler();
  }

  async saveAndAddAnother() {

    this.jobForm.get('meta.timesheet').setValue({
      started: this.jobForm.value.meta.created,
      saved: Date.now(),
      minutes: this.elapsedTime
    });

    // Create and set group ID to link the jobs, if its already there (ie - they're clicking this a second time), use it
    const jobsGroupId = this.jobForm.get('meta.jobsGroupId').value || this.db.createId()
    this.jobForm.get('meta.jobsGroupId').setValue(jobsGroupId);

    const originalJobId = this.currentJobId; // For reference

    if (this.jobForm.valid) {
      const formValue = this.jobForm.value;
      this.loading = true;
      try {
        await this.afs.col("jobs").doc(originalJobId).set(formValue)
        await this.updateJobsGroupIdOnCreateFromJob()
        this.success = true;
        this.loading = false;
        await this.resetForm()
        this.jobForm.get('meta.isAdditionalJob').setValue(true);
        this.router.navigate([`/dartboard/new`], {
          queryParams: {
            createFromJob: originalJobId
          },
          queryParamsHandling: 'merge',
        });
        document.getElementById("newProposal-top").scrollIntoView(true);
      } catch (err) {
        console.log(err)
        this.error = true
      }
      this.loading = false;
    } else {
      this.markFormAsTouched()
    }

  }

  async resetForm() {

    this.isResettingForm = true;
    
    setTimeout( () => {
      this.isResettingForm = false
    }, 1000)

    this.jobForm.get('meta.timesheet').patchValue({
      started: Date.now(),
      saved: null,
      minutes: null
    })

    this.jobForm.get('info').patchValue({
      project: null,
      isSystemWorking: null,
    })

    this.surveyQuestions.controls.forEach(control => {
      control.get('answer')?.reset(null); // Set answer to an empty string
      // You can also use null instead of '' if null is preferred
    });

    this.jobForm.get('cutsheet').reset()
    const cutsheetImages = this.cutsheetImages;
    while (cutsheetImages.length !== 0) {
      cutsheetImages.removeAt(0);
    }
    const cutsheetImagePaths = this.cutsheetImagePaths;
    while (cutsheetImagePaths.length !== 0) {
      cutsheetImagePaths.removeAt(0);
    }
    this.resetUpload();

    this.jobForm.get('addOns').reset()
    const addOnsArray = this.addOns;
    while (addOnsArray.length !== 0) {
      addOnsArray.removeAt(0);
    }

    this.jobForm.get('tonnage').reset()
    this.jobForm.get('templateDescription').reset()
    this.jobForm.removeControl('psis')
    this.setHasSearched(false);

    const newLinkedJobId = this.db.createId()
    this.currentJobId = newLinkedJobId;
    this.elapsedTime = 0;
    this.success = false;
    this.error = false;
    this.loading = false;
    this.filteredType = null,
    this.filteredProposals = null; // reset to empty and only show new;
    this.filteredMultiBrandTemplates = of(null);

    this.jobForm.markAsPristine();
    this.jobForm.markAsUntouched();

    this.jobForm.patchValue({
      status: 'pending',
      meta: {
        id: newLinkedJobId,
        createdBy: this.currentUser.uid,
        created: Date.now(),
        timesheet: {
          started: Date.now(),
          saved: null,
          minutes: null
        }
      }
    })

  }

  onSubmit() {
    this.jobForm.get('meta.timesheet').setValue({
      started: this.jobForm.value.meta.created,
      saved: Date.now(),
      minutes: this.elapsedTime
    });
    if(this.jobForm.valid) {
      this.submitHandler();
    } else {
      this.markFormAsTouched()
    }
  }

  async updateJobsGroupIdOnCreateFromJob() {
    if (!this.isCreateFromJobUpdateNeeded) return
    try {
      await this.afs.col("jobs").doc(this.createFromJob).update({
        'meta.jobsGroupId' : this.jobForm.value.meta.jobsGroupId
    })
    } catch (err) {
      console.log(err)
    }
  }

  async submitHandler() {
    let formValue = this.jobForm.value;
    formValue.draft = true; // set new field as draft

    this.loading = true;
    try {
      await this.afs.col("jobs").doc(this.currentJobId).set(formValue)
      await this.updateJobsGroupIdOnCreateFromJob()
      this.success = true;
      this.loading = false;
    } catch (err) {
      console.log(err)
      this.error = true
    }
    this.loading = false;
    this.router.navigateByUrl(`/dartboard/all/${this.currentJobId}`)
    // this.router.navigateByUrl(`/dartboard/all/${this.currentJobId}?presenting=true`)
  }

  async submitDraftHandler() {
    const formValue = this.jobForm.value;
    try {
      await this.afs.col("jobs").doc(this.currentJobId).set(formValue)
      this.success = true;
      this.loading = false;
      this.router.navigate(['dartboard'])
    } catch (err) {
      console.log(err)
      this.router.navigate(['dartboard'])
    }
    this.router.navigateByUrl(`dartboard/all/`)
    this.router.navigate(['dartboard'])
  }

  async onCancel() {
    this.loading = true;
    try {
      await this.jobForm.value.cutsheet.images.forEach(image => {
        this.storage.refFromURL(image).delete()
      })
      this.success = true;
      this.loading = false;
      this.router.navigateByUrl(`/dartboard/all`)
    } catch (err) {
      console.log(err)
      this.error = true
    }
    this.loading = false;
  }

  ngOnDestroy() {
    this.timerSub.unsubscribe();
    this.userSub.unsubscribe();
    this.cutsheetSub.unsubscribe();
    this.leadSub?.unsubscribe();
    this.routeSub.unsubscribe();
  }


  async onFileSelected(event) {
    let path : string = `jobs/${this.currentJobId}/cutsheet/images/${this.db.createId()}.jpg`;
    // let path : string = `jobs/0001TESTJOB/cutsheet/images/${this.db.createId()}.jpg`;
    this.cutsheetImagePaths.push( new FormControl ( path ) );
    const config = {
      file: event.target.files[0],
      maxSize: 750
    };
    const resizedImage = await this.resizeImage(config)
    try {
      await this.uploadResizedImage(resizedImage,path)
    } catch(err) { 
      console.log(err)
    }
  }

  async resizeImage(settings: IResizeImageOptions) {
    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = (dataURI: string) => {
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
          atob(dataURI.split(',')[1]) :
          unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], {type:mime});
    };
    const resize = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
          if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
          }
      } else {
          if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
          }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL('image/jpeg');
      return dataURItoBlob(dataUrl);
    };

    return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }

        reader.onload = (readerEvent: any) => {
          image.onload = () => ok(resize());
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    })
  };
  

   async uploadResizedImage(file, path) {

    const ref = this.storage.ref(path);

    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize( async() =>  {
        const images = this.jobForm.get('cutsheet.images') as FormArray;
        // const imagePaths = this.jobForm.get('cutsheet.imagePaths') as FormArray;

        if (this.isOnline) {
          this.downloadURL = await ref.getDownloadURL().toPromise().catch(
            err =>  {
              alert(`There was an error with your upload, or it is taking longer than expected. Please upload cutsheet images from the job details at a later time.`)
              console.log(err)
              // this.setUpForOfflineSave()
              this.resetUpload();
              this.loading = false;
            }
          );

          if (this.downloadURL) {
            images.push(
              new FormControl ( this.downloadURL )
            );
          }
          // imagePaths.push(
          //   new FormControl ( path )
          // )
          
          this.waitThenResetUpload();
        } else {
          // imagePaths.push(
          //   new FormControl ( path )
          // );
          this.resetUpload();
          this.loading = false;
        }

      }),
    );

    if (!navigator.onLine) {
      setTimeout(() => {
        this.resetUpload();
      }, 1000);
    }
  }

  trackByFn(index, item) { return index; }

}
