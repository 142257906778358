import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-linked-job-single-sold-breakdown',
  templateUrl: './linked-job-single-sold-breakdown.component.html',
  styleUrls: ['./linked-job-single-sold-breakdown.component.scss']
})
export class LinkedJobSingleSoldBreakdownComponent implements OnInit {

  @Input() prices;
  
  constructor() { }

  ngOnInit(): void {
  }

}
